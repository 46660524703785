.draggableFormItem {
  display: flex;
  align-items: center;
}

.dragHandler {
  margin-top: -25px;
  margin-left: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 40px;
}

.formItem {
  width: 100%;
  min-height: 64px;
}

.addBulletBtn {
  margin-bottom: 24px;
}
