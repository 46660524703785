.button {
  width: 38px !important;
  height: 38px !important;
  background-color: #FFF !important;
  display: flex;
  align-items: center;
  justify-content: center;

  &.overlayActive {
    position: relative;
    z-index: 0;
  }

  &.hidden {
    display: none;
  }
}

