.container {
  display: flex;

  & .secondOptionOpened {
    & > div > div::before {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: none;
    }
  }

  & .asSecondSelect {
    & > div > div::before {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  & .dayOrNumberSelected {
    & > div > div::before {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: 0;
    }
  }

  & .select {
    width: 160px;
  }

  & .ruleInput {
    width: 150px;
  }

  :global(.ant-input) {
    border: 1px solid #5C5C6E !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    border-radius: 8px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    border-left: none;
    border-left-width: 0;

    &:hover {
      border-color: transparent !important;
    }
  }
}

.inputRoot {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;

  &::before {
    width: 100%;
    transition: all 0.1s;
    position: absolute;
    content: '';
    inset: 0;
    visibility: visible;
    z-index: 0;
    border-width: 1px !important;
    border-style: solid;
    border-color: #5c5c6e !important;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &:hover::before {
    transition: all 0.1s;
    border-color: #33bdcd !important;
    border-width: 2px !important;
  }
}

.disabled {
  cursor: not-allowed;

  &:hover::before {
    border-width: 1px !important;
    border-style: solid;
    border-color: #5c5c6e !important;
  }
}
