.root {
  margin-bottom: 16px;

  :global(.ant-card-head) {
    padding: 24px 24px 0;
  }
  :global(.ant-card-body) {
    padding: 16px 24px;
  }
  :global(.ant-card-body) {
    padding: 0;
  }
}

.titleWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  margin-bottom: 24px;
}

.title {
  font-weight: 700;
  font-size: 20px;
}

.text {
  font-weight: 700;
  display: block;
  margin-bottom: 16px;
}

.space {
  display: flex;
  align-items: baseline;
  font-weight: 400;
}

.colorFormSection {
  margin-bottom: 0;
}

.colorFormItem {
  min-height: 64px !important;
}

.switchWrapper {
  display: flex;
  align-items: center;

  button {
    margin-right: 6px;
  }
}
