.ant-menu-dark {
  height: 100%;
  color: #fff;
}

.ant-menu-inline .ant-menu-item {
  height: 48px;
  margin: 0;
  width: 100%;
  padding-left: 16px !important;
}

.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 48px;
  margin: 0;
  width: 100%;
  padding-left: 16px !important;
}

.ant-menu-dark.ant-menu-inline .ant-menu-sub.ant-menu-inline {
  background: #404055;
}

.ant-menu-sub.ant-menu-inline .ant-menu-item {
  padding-left: 48px !important;
}

.ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-selected {
  background: #5c5c6e;

  & .ant-menu.ant-menu-sub.ant-menu-inline {
    background: #5c5c6e;
  }
}

.ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-active.ant-menu-submenu-selected {
  background: #5c5c6e;
}

.ant-menu-item.ant-menu-item-selected {
  font-weight: 700;
}

.ant-menu-item-group-title[role="presentation"] {
  color: #797988;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  padding-top: 16px;
}