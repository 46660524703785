.button {
  width: 38px !important;
  height: 38px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFF !important;
  &.overlayActive {
    position: relative;
    z-index: 1000;
  }
}