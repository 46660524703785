@import '../../../../app/styles/mixins';

.section {
  display: flex;
  justify-content: center;
  min-height: 682px;
  position: relative;
  align-items: center;
  padding-inline: 24px;
  column-gap: 46px;
  overflow: hidden;
  width: 100%;

  @include forLargeDesktop {
    column-gap: 88px;
  }

  @container (min-width: 1200px) and (max-width: 1335px) {
    column-gap: 24px;
  }

  @include for-desktop-down {
    flex-wrap: wrap-reverse;
  }

  @include for-tablet-only {
    padding: 32px;
    justify-content: flex-start;
    min-height: 100%;
  }

  @include for-tablet-down {
    padding: 24px 32px 46px;
    align-items: flex-end;
  }

  & .overlay {
    position: absolute;
    height: 291px;
    top: -24px;
    left: 0;
    right: 0;
    background: linear-gradient(180deg, rgba(234, 238, 248, 0.9) 7.18%, rgba(234, 238, 248, 0.826087) 28.85%, rgba(234, 238, 248, 0.716184) 45.28%, rgba(234, 238, 248, 0.6) 58.06%, rgba(234, 238, 248, 0.2) 81.8%, rgba(234, 238, 248, 0) 93.85%);
  }
  
  & .mainContainer {
    & > div {
      padding-top: 0;
      padding-bottom: 0;
    }

    @include forLargeTablet {
      margin-left: 260px;
    }
  }

  & .headlineBlock {
    padding: 48px;
    max-width: 513px;
    position: relative;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;

    @include for-desktop-up {
      min-height: 400px;
      margin-right: 0;
      margin-bottom: -20px;
    }

    @container (min-width: 1200px) and (max-width: 1335px) {
      padding: 42px;
    }

    @include for-tablet-only {
      padding: 0;
      max-width: 100%;
    }

    & .headlineContainer {
      margin-bottom: 32px;

      @include for-tablet-down {
        margin-bottom: 17px;
      }
    }
  }
}

.arrowIcon {
  position: absolute;
  z-index: 100;

  @include for-tablet-down {
    display: none;
  }

  path {
    fill: var(--primary-color);
  }
}

.productList {
  width: 100%;
}

.productListButton {
  width: 110px;
}

.productName {
  width: 110px;
}