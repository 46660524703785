.root {
  min-height: 68px;
  margin-bottom: 0;

  :global(.ant-form-item-control-input) {
    min-height: unset;
  }

  & :global(.ant-form-item-explain > *) {
    margin: 4px 0 0;
    font-size: 12px;
    color: #E64242;
    letter-spacing: -1.5%;
    text-align: right;
  }

  :global(
  .ant-form-item-explain .ant-form-item-explain-error:not(:first-child)
) {
    display: none;
  }
}
