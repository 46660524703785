@import '../../../../app/styles/mixins';

.wrapper {
  display: flex;
  gap: 13px;

  @include for-desktop-up {
    margin-inline: -100px;
  }

  @include for-tablet-only {
    margin-inline: -35px;
  }

  @include for-phone-only {
    margin-inline: -36px;
  }

  :global(.slick-arrow) {
    display: none !important;
  }

  :global(.slick-list) {
    padding-inline: 84px;

    @include for-desktop-up {
      padding-inline: 84px;
    }

    @include for-tablet-only {
      padding-inline: 20px;
    }

    @include for-phone-only {
      padding-inline: 20px;
    }
  }

  > div {
    overflow: hidden;
  }

  > div > div > div {
    display: flex !important;
    margin-bottom: 10px;
    margin-left: 16px;
  }
}
