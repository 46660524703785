.buttonLink {
  label {
    left: 48px;
  }
}

.space {
  align-items: flex-start;
}

.trashButton {
  margin-top: 12px;
}

.trashIcon {
  & path {
    fill: #5c5c6e;
  }
  &:hover path {
    fill: #da1b5e;
  }
}
