@import '../../../../app/styles/mixins.scss';

.button {
  height: 24px;
  min-width: 118px;
  font-size: 10px !important;
  padding: 4px !important;
  margin-right: 20px;

  @include for-desktop-down {
    margin-right: 18px;
  }

  @include for-small-tablet {
    margin-right: 8px;
  }
}
