@import '../../../../app/styles/mixins';

.wrapper {
  display: flex;
  padding: 40px 24px;
  gap: 30px;
  justify-content: space-between;

  @include for-phone-only {
    flex-direction: column;
    padding: 32px 16px;
    gap: 8px;
  }

  @include for-desktop-up {
    padding: 40px 88px;
  }

  .title {
    margin: 0 0 8px;

    @include for-desktop-down {
      margin: 0 0 8px !important;
      font-size: 20px !important;
      line-height: 27px;
    }
  }

  .subTitle {
    margin: 0 0 16px !important;

    @include for-desktop-down {
      font-size: 16px !important;
      margin: 0 0 24px !important;
    }
  }

  .description {
    margin-bottom: 24px;
    display: block;

    @include for-desktop-down {
      font-size: 14px !important;
    }
  }

  .button {
    background-color: #00a775;
    border-radius: 0;
    width: 300px;

    @include for-tablet-down {
      width: 100%;
    }
  }

  .column {
    width: 50%;

    & > img {
      width: 100%;
      height: 100%;
      max-height: 350px;
      object-fit: cover;
    }
  }
}
