.root {
  margin-bottom: 16px;
  :global(.ant-card-head) {
    padding: 24px;
    font-weight: 400;
  }

  :global(.ant-card-body) {
    padding: 16px 24px;
  }
}

.titleWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.title {
  font-weight: bold;
  font-size: 20px;
}

.tooltipIcon {
  margin-left: 10px;
  cursor: pointer;
}

.text {
  font-size: 12px;
}

.link {
  color: #da1b5e !important;
}

.colorPicker {
  display: flex;
  margin-top: 32px;
}

.formItem {
  width: 20%;
}

.button {
  font-size: 12px;
}
