@import '../../../../app/styles/mixins';

.wrapper {
  width: 304px;
  margin-right: 16px;
  min-height: 128px;
  justify-content: flex-end;
  margin-bottom: 10px;
  box-shadow: 0 4px 20px 0 rgb(0 0 0 / 10%);
  display: flex;
  flex-direction: column;
  color: #89b1d6;
  position: relative;

  @include for-tablet-only {
    min-width: 200px;
    min-height: 200px;
    justify-content: center;
    width: 262px;
  }

  @include for-phone-only {
    width: 264px;
  }

  .img {
    margin-bottom: 16px;
    width: 100%;
    object-fit: cover;
  }

  .name {
    display: block;
    font-weight: bold;
    line-height: 28px;
    margin-bottom: 8px;
  }
}

.text {
  font-size: 12px;
}

.textWrapper {
  padding: 0 24px 24px;
  height: 154px;
  align-self: start;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.imgWrapper {
  width: 100%;
  height: 100%;

  > img {
    height: 186px;
  }
}

.title {
  @include for-desktop-down {
    font-size: 16px !important;
  }
}

.paragraph {
  display: -webkit-box;
  -webkit-line-clamp: 5;
}