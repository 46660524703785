@import '../../app/styles/mixins';

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 64px 104px 0;

  @container (max-width: 1447px) {
    padding: 64px 88px 0;
  }

  @container (max-width: 1320px) {
    padding: 64px 64px 0;
  }

  & .wrapper {
    padding: 64px 104px 0;
    margin: -64px -104px 0;
    flex: 1;
    background: linear-gradient(76deg, rgb(0 0 0 / 51%) 5.37%, rgb(0 0 0 / 46%) 27.78%, rgb(0 0 0 / 0%) 90.96%);
  }

  @include for-desktop-down {
    display: none;
  }

  & .header {
    width: 100%;
    margin-bottom: 32px;

    & .title {
      margin-bottom: 16px;
      word-break: normal!important;
      overflow-wrap: normal;
      color: #fff!important;
      width: 95%;
      hyphens: auto;

      @container (max-width: 1400px) {
        margin-bottom: 16px;
        white-space: break-spaces;
        color: #fff !important;
        width: 16ch!important;
      }
    }

    & .subTitle {
      margin: 0;
      width: 85%;
      color: #fff !important;
      hyphens: auto;
    }
  }

  & .houseModelWrapper {
    display: flex;

    //& > div {
    //  width: 70% !important;
    //
    //  & > div {
    //    width: calc(50% - 12px) !important;
    //
    //    & > div {
    //      width: 100% !important;
    //      height: 270px;
    //    }
    //  }
    //}
  }
}