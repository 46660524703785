@import '../../../../app/styles/mixins';

.wrapper {
  background-color: #f5f5f6;
  border-radius: 4px;
  padding: 16px 16px 16px 12px;
  flex-grow: 1;

  @include for-tablet-down {
    overflow: auto;

    @include hide-scrollbar;
  }

  @include for-phone-only {
    width: 100%;
  }

  .title {
    display: block;
    line-height: 1.6;
    margin: 0 0 16px;

    @include for-tablet-down {
      line-height: 27px;
    }
  }

  .list {
    display: flex;
    margin-bottom: 16px;
  }

  .divider {
    height: auto;
    margin: 0 7px;
    border-inline-start-style: dashed;
  }
}
