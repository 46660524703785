$sameWidth: true;

.ant-segmented {
  border-radius: 8px;
  border: 1px solid #191919;
  padding: 2px;
  background: transparent;

  .ant-segmented-thumb,
  .ant-segmented-item-selected {
    background: #da1b5e;
  }

  .ant-segmented-item-selected .ant-segmented-item-label {
    color: #fff !important;
  }

  .ant-segmented-thumb,
  .ant-segmented-item,
  .ant-segmented-item-selected {
    border-radius: 6px !important;
  }

  .ant-segmented-item-label {
    line-height: unset;
    height: unset;
    min-height: unset;
    color: #191919;
    font-weight: 700;
    font-size: 12px;
    padding: 2px 10px;
    transition: 0.1s all;
  }

  .ant-segmented-item-selected::after {
    background: unset !important;
  }

  .ant-segmented-item,
  .ant-segmented-item-selected {
    overflow: hidden;
    min-width: unset;
  }
}

@if $sameWidth {
  .ant-segmented-group {
    display: grid;
    grid-auto-columns: minmax(0, 1fr);
    grid-auto-flow: column;
    justify-items: unset;
  }
}
