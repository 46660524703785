.wrapper{
  display: flex;

  &_children{
    max-height: calc(100dvh - 75px);
    overflow: auto;
    padding: 24px 32px;
    width: 100%;
  }
}
