.validationItems {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: -15px;
  margin-bottom: 29px;


  .checkValidationItem {
    gap: 4px;
    display: flex;
    align-items: center;

    &:nth-child(5) {
      margin-right: 114px;
    }


    .checkValidationItemText {
      font-size: 12px;
    }
  }
}