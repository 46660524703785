@import './../../../../app/styles/mixins';

.container {
  padding: 60px 88px;

  @include for-tablet-only {
    padding: 40px 24px;
  }

  @include for-phone-only {
    padding: 24px 16px;
  }

  & .title {
    margin: 0;

    @include for-tablet-only {
      font-size: 20px !important;
    }

    @include for-phone-only {
      font-size: 16px !important;
    }
  }

  & .list {
    display: flex;
    flex-direction: column;

    @include for-phone-only {
      row-gap: 20px;
    }

    & .bonusItem {
      display: flex;
      flex-direction: column;
      padding: 16px 0;
      border-bottom: 1px solid #cfcfd4;

      &:last-child {
        border-bottom: none;
      }

      @include for-phone-only {
      }

      & .imageContainer {
        margin-right: 24px;

        @include for-tablet-only {
          margin-right: 16px;
        }

        @include for-phone-only {
          margin-right: 16px;
        }

        & .image {
          display: block;
          width: 143px;
          height: 88px;

          @include for-phone-only {
            width: 88px;
            height: 88px;
            border-radius: 3px;
          }
        }
      }

      & .details {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-left: 24px;

        @include for-tablet-only {
          margin-left: 16px;
        }

        @include for-phone-only {
          margin-left: 0;
        }

        & .detailsLeftSide {
          display: flex;
          max-width: 45%;

          @include for-desktop-down {
            max-width: 40%;
          }

          @include for-tablet-down {
            max-width: 65%;
          }
        }

        & .bonusTitle {
          margin-bottom: 8px;

          @include for-desktop-down {
            font-size: 16px !important;
          }

          @include for-phone-only {
            font-size: 14px !important;
            font-weight: 400 !important;
          }
        }

        & .bonusName {
          display: block;
          text-align: center;
          text-wrap: nowrap;

          @include for-desktop-down {
            font-size: 14px !important;
          }
        }

        & .description {
          line-height: 20px;
          margin: 0;

          @include for-tablet-down {
            margin-bottom: 16px;
          }
        }
      }
    }
  }
}

.headerWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgb(97 97 97 / 30%);
  margin-bottom: 16px;
  padding-bottom: 16px;
}

.detailsRightSide {
  width: 50%;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;

  @include for-desktop-down {
    width: 55%;
  }

  @include for-tablet-down {
    width: 35%;
    justify-content: flex-end;
    align-items: flex-start;
  }
}

.radioWrapper {
  border-radius: 8px;
  border: 1px solid#444;
  padding: 2px;
  max-width: 146px;
  height: 24px;

  @include for-phone-only {
    height: 32px;
  }
}

.buttonActivation {
  height: 24px;

  @include for-phone-only {
    height: 32px;
  }

  background-color: transparent;
  border-radius: 8px;
  border-color: #444;
  color: #444;
  font-weight: 700;
  font-size: 12px;
  padding: 0 12px;
}

.buttonShowMore {
  display: flex;
  color: #444;
  font-size: 14px;
}

.buttonShowMoreWrapper {
  align-items: center;
  display: flex;
  justify-content: center;
}

.mobileButtonsWrapper {
  margin-top: 20px;
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
}
