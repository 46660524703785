@import '../../app/styles/mixins';

.сontainer {
  margin-left: 8px;
  margin-right: 8px;
  max-width: 704px;
}

.mainContainer {
  position: relative;
  height: auto;

  & .circleButton {
    width: 155px;
    height: 155px;
    border-radius: 50%;
    z-index: 100;
    position: absolute;
    top: -3%;
    left: 73%;
    background: linear-gradient(180deg, #60b11f 0%, #007829 100%);
    filter: drop-shadow(0 4px 20px rgb(0 0 0 / 10%));
    transform: rotate(14.244deg);
    color: #fff;
    font-size: 22px;
    font-weight: 700;
    white-space: normal;
    transition: 0.3s all;
    border: none;
    cursor: auto;

    &:hover,
    &:active {
      color: #fff !important;
    }
  }
}


.section {
  position: relative;
  background-size: cover;
  background-position: left;
  background-repeat: no-repeat;
  min-height: 703px;
  align-items: center;
  justify-content: center;
  gap: 30px;
  background-color: linear-gradient(68deg, rgb(0 0 0 / 20%) 0%, rgb(0 0 0 / 0%) 64.29%);
  display: flex;
  flex-wrap: nowrap;

  @include for-desktop-down {
    flex-wrap: wrap-reverse;
  }

  padding: 40px 24px;
}

.pentagon {
  width: 100%;
  height: 310px;
  position: relative;
  display: flex;
  clip-path: polygon(50% 0%, 100% 57%, 100% 100%, 0% 100%, 0% 57%);
  background-color: #fff;
}

.trapezoidCard {
  padding: 4px;
  flex: 1;
  box-shadow:
    0 5px 30px 0 rgb(0 0 0 / 5%),
    1px 4px 5px 0 rgb(0 0 0 / 30%);
}

.pentagon .pentagonSector {
  top: 0;
  left: 0;
  width: 352px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pentagon .pentagonSector div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 90%;
  gap: 8px;
  margin-bottom: 18px;

  > .trapezoidCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    cursor: pointer;

    .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%);
      width: 40px;
      height: 40px;
    }

    .subText {
      align-self: center;
    }
  }
}

.pentagon .pentagonSector:nth-child(1) div {
  .trapezoidCard:nth-child(1) {
    .icon {
      top: 67%;
    }
  }

  .trapezoidCard:nth-child(2) {
    .icon {
      top: 54%;
    }
  }

  .trapezoidCard:nth-child(3) {
    .icon {
      top: 29%;
    }
  }
}

.pentagon .pentagonSector:nth-child(2) div {
  .trapezoidCard:nth-child(1) {
    .icon {
      top: 29%;
    }
  }

  .trapezoidCard:nth-child(2) {
    .icon {
      top: 54%;
    }
  }

  .trapezoidCard:nth-child(3) {
    .icon {
      top: 67%;
    }
  }
}

.pentagon .pentagonFourSector.pentagonSector:nth-child(1) div {
  .trapezoidCard:nth-child(1) {
    .icon {
      top: 67%;
    }
  }

  .trapezoidCard:nth-child(2) {
    .icon {
      top: 55%;
    }
  }

  .trapezoidCard:nth-child(3) {
    .icon {
      top: 44%;
    }
  }
  .trapezoidCard:nth-child(4) {
    .icon {
      top: 28%;
    }
  }
}

.pentagon .pentagonFourSector.pentagonSector:nth-child(2) div {
  .trapezoidCard:nth-child(1) {
    .icon {
      top: 28%;
    }
  }

  .trapezoidCard:nth-child(2) {
    .icon {
      top: 44%;
    }
  }

  .trapezoidCard:nth-child(3) {
    .icon {
      top: 55%;
    }
  }
  .trapezoidCard:nth-child(4) {
    .icon {
      top: 67%;
    }
  }
}

// Only two items

.pentagon .pentagonSecondSector.pentagonSector:nth-child(1) div {
  .trapezoidCard:nth-child(1) {
    .icon {
      top: 64%;
    }
  }

  .trapezoidCard:nth-child(2) {
    .icon {
      top: 38%;
    }
  }
}

.pentagon .pentagonSecondSector.pentagonSector:nth-child(2) div {
  .trapezoidCard:nth-child(1) {
    .icon {
      top: 38%;
    }
  }

  .trapezoidCard:nth-child(2) {
    .icon {
      top: 64%;
    }
  }
}

// end

.pentagon .pentagonSector:nth-child(1) {
  clip-path: polygon(98% 8%, 98% 100%, 5.8% 100%, 5.8% 61%);
  background: linear-gradient(0deg, rgb(255 255 255 / 20%) 0%, rgb(255 255 255 / 20%) 100%);
  position: relative;
}

.pentagon .pentagonSector:nth-child(2) {
  clip-path: polygon(2.1% 8%, 94.3% 61%, 94.3% 100%, 2.1% 100%);
  background: linear-gradient(0deg, rgb(255 255 255 / 20%) 0%, rgb(255 255 255 / 20%) 100%);
  position: relative;
}

.pentagon .pentagonSector:nth-child(1) > div {
  width: 100%;
  height: 100%;
  clip-path: polygon(96% 13%, 96% 100%, 8% 100%, 8% 67%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 30px;
  padding-right: 15px;
}

.pentagon .pentagonSector:nth-child(2) > div {
  width: 100%;
  height: 100%;
  clip-path: polygon(4% 13%, 92% 67%, 92% 100%, 4% 100%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 30px;
}

.rectangle {
  width: 100%;
  height: 260px;
  top: 100%;
  left: 0;
  padding-top: 15px;
  padding-bottom: 20px;
  margin-top: -2px;
  display: flex;
  background-color: #fff;
}

.rectangle .rectangleSector {
  width: 50%;
  height: 100%;
  background: linear-gradient(0deg, rgb(255 255 255 / 20%) 0%, rgb(255 255 255 / 20%) 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.rectangle .rectangleSector:nth-child(1) {
  clip-path: polygon(5.8% 0%, 98% 0%, 98% 100%, 5.8% 100%);

  & > div {
      padding-left: 30px;
      padding-right: 15px;
  }
}


.rectangle .rectangleSector:nth-child(2) {
  clip-path: polygon(2.1% 0%, 94.3% 0%, 94.3% 100%, 2.1% 100%);
  background: linear-gradient(0deg, rgb(255 255 255 / 20%) 0%, rgb(255 255 255 / 20%) 100%);

  & > div {
    padding-left: 15px;
    padding-right: 30px;
  }
}

.cardsWrapper {
  width: 100%;
  height: 180px;
  gap: 8px;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.card {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 4px;
  cursor: pointer;
  box-shadow:
    0 5px 30px 0 rgb(0 0 0 / 5%),
    1px 4px 5px 0 rgb(0 0 0 / 30%);

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    height: 100%;

    .icon {
      margin-top: 50px;
      width: 40px;
      height: 40px;
      margin-bottom: auto;
    }

    .subText {
      align-self: flex-end;
    }
  }
}

.headline4 {
  margin-bottom: 8px;
}

.headlineBlock {
  position: relative;
  max-width: 538px;
  margin-right: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;

  @include for-desktop-up {
    min-height: 400px;
    margin-right: 0;
    margin-bottom: -20px;
  }
}

.arrowIcon {
  position: absolute;
  right: 80%;
  top: -20%;
  z-index: 100;

  @include for-desktop-down {
    left: 120%;
    top: 60%;
    transform: rotateY(180deg) rotateZ(320deg);
  }
}

.title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 3;
  text-align: center;
  hyphens: auto;
}