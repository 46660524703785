@import '../../../../app/styles/mixins';

.wrapper {
  width: 100%;
  min-width: 262px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  transition: .2s ease;

  &:hover {
    box-shadow: 0 2px 18px 0 rgb(0 0 0 / 25%);
    transform: scale(1.02);
  }

  @include for-tablet-down {
    padding: 24px 24px 40px;
  }

  .icon {
    width: 50px;
    height: 50px;
    margin-bottom: 16px;
  }

  .title {
    margin-bottom: 8px;
    color: currentColor !important;

    @include for-desktop-down {
      font-size: 16px !important;
    }
  }

  .description {
    color: currentColor !important;

    @include for-desktop-down {
      font-size: 12px !important;
    }
  }
}
