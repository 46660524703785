@import '../../../../app/styles/mixins';

.container {
  padding-top: 48px;
  padding-bottom: 56px;

  :global(.slick-list) {
    padding-inline: 88px;

    @include for-desktop-up {
      padding-inline: 84px;
    }

    @include for-tablet-only {
      padding-inline: 20px;
    }
  }

  :global(.slick-track) {
    display: flex !important;
  }

  :global(.slick-slide) {
    height: inherit;
    display: flex;

    & > div {
      display: flex;
      flex-grow: 1;
    }
  }


  & .header {
    padding-inline: 88px;

    @include for-phone-only {
      padding-inline: 0;
    }

    @include for-tablet-only {
      padding-inline: 24px;
    }

    @include for-desktop-up {
      padding-inline: 88px;
    }
  }

  @include for-phone-only {
    display: flex;
    flex-direction: column;
    padding-inline: 16px;
  }

  & .title {
    margin: 0;
    margin-bottom: 8px !important;

    @include for-phone-only {
      font-size: 20px !important;
    }

    @include for-tablet-only {
      font-size: 20px !important;
      font-weight: 700 !important;
    }
  }

  & .subTitle {
    margin-bottom: 0;
    margin-top: 0 !important;

    @include for-phone-only {
      font-size: 14px !important;
      font-weight: 400 !important;
    }

    @include for-tablet-only {
      font-size: 14px !important;
      font-weight: 400 !important;
    }
  }

  & .list {
    display: flex;
    column-gap: 16px;
    margin-top: 40px;
    cursor: pointer;

    @include for-phone-only {
      flex-direction: column;
      row-gap: 12px;
      margin-top: 30px;
    }
  }
}
