@import '../../../../app/styles/mixins';

.container {
  padding: 32px;
  width: 528px;
  height: 500px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 20px 0 rgb(0 0 0 / 10%);

  @include for-desktop-down {
    width: 375px;
    height: 560px;
    padding: 24px;
  }

  @include for-phone-only {
    width: 100%;
    height: 100dvh;
    border-radius: 0;
  }

  & .logo {
    max-width: 240px;
    height: 49px;
    object-fit: cover;

    @include for-desktop-down {
      max-width: 152px;
      height: 32px;
    }

    @include for-phone-only {
      max-width: 142px;
      height: 32px;
    }
  }

  & .icon {
    margin-right: 8px;
  }

  & .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 48px;
  }

  & .tooltip {
    display: flex;
    align-items: center;
    gap: 7px;
  }

  & .main {
    margin-top: 43px;
    margin-bottom: auto;

    & .title {
      margin-bottom: 32px;
      font-size: 30px !important;

      @include for-desktop-down {
        margin-bottom: 24px;
        font-size: 24px !important;
      }
    }

    & .text {
      margin-bottom: 32px;
      display: block;
    }

    & .formItem {
      min-height: 72px;
    }

    & .sendButton {
      margin-top: 8px;
      width: 100%;
    }

    & .resetPasswordButton {
      margin-top: 16px;
      padding: 0;
    }

    & .bottomButtons {
      margin-top: 56px;
      display: flex;
      flex-direction: column;
      row-gap: 16px;
    }
  }
}

.successWrapper {
  display: flex;
  margin-bottom: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 43px;

  & .title {
    text-align: center;
    margin-bottom: 32px;
    font-size: 30px !important;

    @include for-desktop-down {
      margin-bottom: 24px;
      font-size: 24px !important;
    }
  }

  & .text {
    margin-bottom: auto;
    margin-top: 24px;
    display: block;
    text-align: center;
  }
}
