@import '../../../../app/styles/mixins';

.wrapper {
  display: flex;
  align-items: center;

  @include for-desktop-down {
    justify-content: space-between;
  }

  .leftColumn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
  }

  .leftColumnPadding {
    margin-left: 28px;
  }

  .rightColumn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 1;

    & .innerColumn {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: max-content;
    }
  }
}

.name {
  font-size: 12px !important;
  font-weight: 400 !important;
  display: inline-block;

  width: 210px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  @include for-desktop-down {
    width: auto;
    min-width: 150px;
  }

  @container (max-width: 1200px) {
    width: auto;
    white-space: normal;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 10px;
  }

  @container (max-width: 790px) {
    width: auto;
    white-space: normal;

    min-width: 80px;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 2px;
  }
}

.iconButton {
  width: 16px !important;
  height: 16px !important;
  background-color: transparent !important;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-size: 16px !important;
  }
}

.trashIcon {
  margin-right: 4px;
  fill: #5c5c6e;
  cursor: pointer;
}

.points {
  display: flex;
  justify-content: flex-end;
  width: 50px;
  white-space: nowrap;

  @include for-small-tablet {
    width: 45px;
  }
}

.itemIcon {
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;

  > img {
    width: 22px;
    height: 22px;
  }

  .icon {
    width: 22px;
    height: 22px;
  }
}
