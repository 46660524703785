@import '../../../../app/styles/mixins';

.sliderContainer {
  display: flex;
  flex-direction: column;
  padding-top: 24px;

  @include for-phone-only {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 16px;
    margin-bottom: 20px;
  }
}