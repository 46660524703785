.container {
  display: flex;
  justify-content: space-between;
  width: 100%;

  & .button {
    padding: 0;
    display: flex;
    align-items: center;
    height: auto;
  }

  & .text {
    margin: 0;
  }

  & .icon {
    margin-right: 8px;
  }
}