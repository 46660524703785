.form {
  padding-bottom: 16px;

  .formText {
    margin-bottom: 16px;
  }
}

.formInputsRow {
  display: flex;
  gap: 16px;
  margin-bottom: 4px;

  > div {
    width: 100%;
  }

  .textArea {
    & :global(.ant-form-item-explain > *) {
      padding-right: 60px;
    }
  }

  .inputLabel {
    top: 20px;
  }

  .inputTextArea {
    & > textarea {
      background-color: transparent !important;
    }
  }
}

.textAreaRow {
  margin-bottom: 12px;
}

.textAreaRow2 {
  margin-bottom: 26px;
}

.checkbox {
  align-self: flex-start;
}

.radioGroup {
  display: flex;
  margin-bottom: 24px;
  gap: 8px;

  :global(.ant-radio-button-wrapper) {
    border-radius: 8px;
    font-weight: normal;
    border-inline-start-width: 1px;

    &::before {
      display: none;
    }
  }
}
