.title {
  font-weight: bold;
}

.label {
  margin-bottom: 12px;
}

.footer_label {
  margin-top: 13px;
}

.color_picker {
  margin-top: 20px;
  display: flex;

  &_form_item {
    margin-right: 16px;
    width: 210px;
    margin-bottom: 0;

    &:last-child {
      margin-right: 0;
    }
  }

  &_wrap {
    height: 56px;
    width: 100%;
    border: 1px solid #cfcfd4;
    border-radius: 8px;
    cursor: pointer;
    box-sizing: border-box;
    margin-bottom: 10px;
  }
}
