.root {
  width: 100%;
  margin: 0 auto;
  background: linear-gradient(107.56deg, #f8f5ff 0%, #fff 51.56%, #f6f4ff 100%);
  min-height: 100vh;
}

.endUserRoot {
  width: 100%;
  margin: 0 auto;
}
