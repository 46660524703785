.modal {
  min-width: 664px;

  .form {
    display: flex;
    flex-direction: column;

    &_dateInputsWrapper {
      display: flex;
      gap: 16px;
    }
  }

  .commentWrapper {
    margin-bottom: 24px;
  }

  .textArea {
    height: 80px;

    textarea {
      resize: none;
      background-color: transparent;
    }
  }

  .datepickerItem {
    height: 40px;
    padding: 20px 10px !important;
    width: 300px;
    border-radius: 8px;
  }
}
