.header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: linear-gradient(180deg, rgb(255 255 255 / 4%) 0%, rgb(255 255 255 / 0%) 41.2%),
    linear-gradient(287.86deg, #282828 0%, #404055 48.28%, #141414 98.62%) !important;
  height: 50px;
  padding-inline: 24px;
  position: sticky;
  top: 0;
  z-index: 1;

  .link {
    display: flex;
    align-items: center;
    position: absolute;
    left: 24px;
  }

  .linkIcon {
    height: 19px;
    margin-right: 8px;

    path {
      fill: #fff;
    }
  }

  .linkText {
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.015em;
    color: #fff;
  }

  .radioGroup {
    display: flex;
    justify-content: space-between;
    gap: 8px;

    :global(.ant-radio-button-wrapper) {
      padding: 0 16px;
      border-radius: 8px;
      font-weight: normal;
      border-inline-start-width: 1px;
      border-color: transparent;
      background-color: transparent;

      &::before {
        display: none;
      }

      &:hover {
        background-color: #ffffff1a;
        border-color: transparent;
      }
    }

    :global(.ant-radio-button-wrapper-checked) {
      background-color: #ffffff1a;

      &:hover {
        background-color: #ffffff1a;
        border-color: transparent;
      }
    }

    :global(.ant-radio-button ~ span) {
      display: flex;
      align-items: center;
      gap: 8px;
      color: #fff;

      & > svg path {
        stroke: currentColor;
      }
    }
  }
}
