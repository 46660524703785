.container {
  position: relative;

  & .conditionContainer {
    margin-top: 16px;
    width: 100%;
    display: flex;
    flex-direction: column;

    & .header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      & .conditionTitle {
        font-weight: 700;
        font-size: 16px;
      }

      & .rightButtons {
        display: flex;
        align-items: center;
        column-gap: 16px;

        .button {
          height: 24px;
          padding: 0 16px;
          font-weight: bold;
          font-size: 12px;
          line-height: 12px;
          color: #191919;
          border: 1px solid #191919;
          background: none;
        }

        :global(.icon_btn) {
          &:disabled {
            & > svg {
              fill: #CFCFD4
            }
          }
        }

        & .iconButtonSecond {
          transform: rotateX(180deg);
        }
      }
    }



    & .ruleContainer {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 10px;
      min-height: 68px;
      padding: 16px 16px 12px;
      border-radius: 8px;
      gap: 16px;
      border: 1px solid #CFCFD4;
      background-color: #ffffff;

      & > .deleteButton {
        width: 24px;
        height: 24px;
      }
    }
  }
}