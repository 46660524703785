.root {
  width: 100%;
  margin-bottom: 16px;
  box-shadow: 0 4px 20px rgb(0 0 0 / 10%);

  :global(.ant-card-body) {
    padding: 24px 24px 0;
  }
}

.section {
  display: flex;
  gap: 16px;

  & > div {
    width: 100%;
  }
}

.tooltipIcon {
  margin-left: 10px;
  cursor: pointer;
}
