.root {
  position: relative;
  display: flex;
}

.disabled {
  cursor: not-allowed !important;
  opacity: 0.4;
}

.content {
  display: flex;
  align-items: center;
}

.customCheckbox {
  display: flex;
  align-items: center;
  border: 1px solid #191919;
  height: 16px;
  min-width: 16px;
  border-radius: 2px;
  justify-content: center;

  &__error {
    border-color: #e64242;
  }

  svg {
    visibility: hidden;
  }
}

.input {
  position: absolute;
  inset: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  padding: 0;

  &:checked ~ .content .customCheckbox {
    svg {
      visibility: visible;
    }
  }
}

.label {
  margin-left: 8px;
  font-weight: normal !important;
  line-height: 1;
}
