@import '../../../../app/styles/mixins';

.container {
  :global(.slick-arrow) {
    display: none !important;
  }

  :global(.slick-list) {
    padding-inline: 84px;

    @include for-desktop-up {
      padding-inline: 84px;
    }

    @include for-tablet-only {
      padding-inline: 20px;
    }
  }

  :global(.slick-track) {
    display: flex !important;
  }

  :global(.slick-slide) {
    height: inherit;
    display: flex;

    & > div {
      display: flex;
      flex-grow: 1;
    }
  }
}

.dotsContainer {
  display: flex;
  column-gap: 12px;
  margin-inline: 24px;

  & .dot {
    display: block;
    padding: 0;
    transition: 0.2s;
    width: 12px;
    height: 12px;
    border-radius: 100%;
    cursor: pointer;
    border: none;

    &:active,
    &:hover {
      border-color: transparent !important;
      color: transparent !important;
    }

    &:focus-visible {
      outline: none;
    }
  }

  & .dotActive {
    width: 32px;
    border-radius: 17px;
  }
}
