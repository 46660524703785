.imageSelectWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  height: 120px;
  border-radius: 8px;
  padding: 7px;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
  margin-bottom: 32px;
  border: 1px solid #00acc0;
  &:hover {
    border: 1px solid #00acc0;
  }
}

.closed {
  border: 1px solid #5c5c6e;
}

.formSection {
  margin: 0;
}

.optionsWrapper {
  margin-top: 10px;
  height: 100%;
  overflow-y: scroll;
}

.picture {
  width: 204px;
  height: 104px;
  margin-right: 16px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
}

.pictureNotSelected {
  background: #f2f2f3;
}

.disabledPicture::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: #dadadaa8;
}

.content {
  display: flex;
  flex-direction: column;
  max-height: 404px;
  min-height: 246px;
  padding: 12px 8px;
  background-color: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 4;
}

.text {
  width: calc(100% - 254px);
  margin-right: 8px;
}

.label {
  width: calc(100% - 254px);
  pointer-events: none;
  transition: all 0.2s ease 0s;
  color: #9696a2;
}

.labelSelected {
  width: auto;
  position: absolute;
  font-size: 12px;
  top: -12px;
  left: 16px;
  background: white;
  color: #9696a2;
}

.arrowIcon {
  fill: #5c5c6e;
}

.revertArrowIcon {
  composes: arrowIcon;
  transform: rotateZ(180);
}
