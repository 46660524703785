@import '../../../../app/styles/mixins';

.smallHouseContainer {
  position: absolute;
  right: 88px;
  top: 50px;
  width: 110px;
  height: 98px;
  z-index: 10;
  background-image: url("components/smallHouse.svg");
  background-size: cover;
  background-repeat: no-repeat;
  @include forLaptops {
    right: 30px;
  }
  @include for-tablet-down {
    right: 0;
  }
}

.topLeftElement {
  position: absolute;
  left: 5px;
  top: 4px;
  width: 48px;
  height: 48px;
  z-index: 10;
  cursor: pointer;
}

.topRightElement {
  position: absolute;
  right: 5px;
  top: 4px;
  width: 48px;
  height: 48px;
  z-index: 10;
  cursor: pointer;
}

.bottomLeftElement {
  position: absolute;
  left: 5px;
  top: 56px;
  width: 48px;
  height: 36px;
  z-index: 10;
  cursor: pointer;
}

.bottomRightElement {
  position: absolute;
  right: 5px;
  top: 56px;
  width: 48px;
  height: 36px;
  z-index: 10;
  cursor: pointer;
}