.title {
  margin-bottom: 4px !important;
  text-align: center;
}

.text {
  text-align: center;
}

.modal {
  :global(.ant-modal-body) {
    display: flex;
    flex-direction: column;
    align-items: center;

    position: relative;
  }
}

.iconCup {
  position: absolute;
  top: 20px;
}

.confettiImg {
  margin-bottom: 8px;
}
