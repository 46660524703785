@import '../../../../app/styles/mixins';

.progressBarWrapper {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;

  @include for-tablet-landscape-up {
    left: 57%;
  }

  left: 55%;
  z-index: 100;

  & .title {
    @include for-tablet-only {
      font-size: 20px !important;
    }
  }

  & .subline {
    @include for-tablet-only {
      font-size: 12px !important;
    }
  }
}

.eviProgress {
  left: 10%;
  transform: translate(-15%, -50%);
}

.progressBar {
  margin-bottom: 12px;

  :global(.ant-progress-steps-item) {
    border-radius: 4px;
    height: 12px !important;

    @include for-tablet-landscape-up {
      width: 89px !important;
    }

    width: 60px !important;

    box-shadow:
      0 5px 30px 0 rgb(0 0 0 / 5%),
      1px 4px 5px 0 rgb(0 0 0 / 30%);
  }

  :global(.ant-progress-steps-item-active) {
    background: #85a63f;
    border-radius: 4px;
    height: 12px;
  }

  :global(.ant-progress-steps-outer) {
    gap: 8px;
  }
}

.progressBarNew {
  margin-bottom: 12px;

  :global(.ant-progress-steps-item) {
    border-radius: 4px;
    height: 12px !important;

    @include for-tablet-landscape-up {
      width: 89px !important;
    }

    width: 60px !important;

    box-shadow: 1px 2px 4px 0px #0000004D inset;
  }

  :global(.ant-progress-steps-item-active) {
    background: #85a63f;
    border-radius: 4px;
    height: 12px;
  }

  :global(.ant-progress-steps-outer) {
    gap: 8px;
  }
}