.header {
  margin-bottom: 32px;

  & .title {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: -0.3px;
  }
}

.switchContainer {
  display: flex;
  column-gap: 8px;
  margin-bottom: 32px;

  & .text {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.24px;
  }
}

.row {
  display: flex;
  justify-content: space-between;
  column-gap: 16px;

  & > div {
    flex: 1
  }
}