.singleSelect {
  cursor: pointer !important;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  padding: 9.5px 16px;
  background: none !important;
  transition: all .2s ease-out;
  color: unset !important;

  &:hover {
    background: #fbe8ef !important;

    .SingleSelectOptionCross {
      visibility: visible !important;
    }
  }

}

.react-select__option--is-selected {
  color: #DA1B5E !important;
}


.SingleSelectOptionCross {
  visibility: hidden !important;
}




