@import '../../../../app/styles/mixins';

.container {
  width: 100%;
}

.headerText {
  margin-bottom: 13px;
}

.productCard {
  width: 95%;
  background-color: #fff;
  padding: 16px;
  margin-bottom: 32px;

  @include for-desktop-up {
    margin-bottom: 0;
  }
}

.productCardWithoutContent {
  width: 75%;
}

.productCardHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgb(68 68 68 / 20%);
  margin-bottom: 16px;
  padding-bottom: 13px;

  > p {
    margin: 0;
  }
}

.productCardList {
  width: 100%;
  padding: 0;
  margin: 0;
  flex-direction: column;
  display: grid;
  row-gap: 10px;
  column-gap: 10px;
  max-height: 160px;
  overflow-y: auto;

  @include hide-scrollbar();
}

.scrollBarContent {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.trackY {
  transform: translateX(5px);
}

:global #wishlistYScroll {
  width: 4px !important;
}

.itemWrapperWithScrollbar {
  padding-right: 6px;
}

.itemWrapperWithoutScrollbar {
  padding-right: 0;
}

.progressBar {
  border-radius: 8px;
  width: 32px;
  height: 11px;
  background: repeating-linear-gradient(
    -45deg,
    var(--primary-color),
    var(--primary-color) 5px,
    color-mix(in srgb, var(--primary-color) 50%, transparent) 5px,
    color-mix(in srgb, var(--primary-color) 50%, transparent) 10px
  );
}

.progressBarWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}


.emptyListText {
  font-weight: 400 !important;
}