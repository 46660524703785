.box {
  min-height: 60px;
  max-height: 60px;
  border-radius: 8px;
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  border: 1px solid #5c5c6e;
  padding: 8px;
}

.deleteButton {
  border: none;
  display: flex;
  align-items: center;
}

.boxNotUploaded {
  composes: box;
  display: flex;
  max-height: 60px;
  flex-direction: column;
  align-items: center;
  border: 1px solid #5c5c6e;
  margin-bottom: 0;
  width: auto;
  margin-bottom: 24px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  min-height: 60px;
  padding: 6px 24px 6px 6px;
  gap: 16px;
  > div > div {
    max-width: 460px;
  }
  > span {
    margin-top: 0;
  }
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 94%;
}

.textContainer {
  display: flex;
  align-items: center;
  max-width: 55%;
}

.uploadTitle {
  color: #9696a2;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.24px;
  margin-top: 8px;
}

.mediaRowImageWrapper {
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 24px;
}

.mediaRowImage {
  min-height: 52px;
  border-radius: 8px;
}

.mediaRowFileName {
  font-weight: 700;
  color: #da1b5e;
}

.fileUploader {
  animation: loadingCircle 1s infinite linear;

  @keyframes loadingCircle {
    100% {
      transform: rotate(360deg);
    }
  }
}
