.root {
  display: flex;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  outline: 0;
  cursor: pointer;
  color: #da1b5e;
}
