@import 'src/app/styles/mixins';

.wrapper {
  display: flex;
  flex-direction: column;
  padding: 40px 88px;
  gap: 24px;

  @include for-desktop-down {
    padding: 40px 24px;
  }

  .title {
    line-height: normal;
    letter-spacing: -0.4px;

    @include for-desktop-down {
      line-height: normal;
    }
  }

  .bonusesFullScreen {
    display: flex;
    gap: 16px;
  }

  .bonuses {
    padding: 20px;
    margin: -20px;
    overflow: hidden;
    & > div > div {
      overflow: visible;
    }
    & > div > div > div {
      display: flex;
      justify-content: stretch;
      gap: 16px;
      overflow: visible;
    }
    & > div > div > div > div {
      height: unset;
      display: flex;
    }
    & > div > div > div > div > div {
      display: flex;
      flex: 1;
    }
  }
}
