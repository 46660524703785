.root {
  margin-top: 5px;
}

.modal {
  min-width: 664px;

  .form {
    display: flex;
    flex-direction: column;
  }
}

.subtitle {
  margin-bottom: 13px;
  font-weight: bold;
  line-height: 16px;
  font-size: 16px;
}

.title {
  display: block;
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: 700;
}

.payoutOptions {
  padding: 24px 0 32px;
  border-top: 1px solid #cfcfd4;
  border-bottom: 1px solid #cfcfd4;

  .radioGroup {
    display: flex;
    gap: 32px;
    min-height: auto;

    :global(.ant-radio-wrapper .ant-radio-inner) {
      width: 16px;
      height: 16px;
    }
    :global(.ant-radio-wrapper) {
      margin-inline-end: 0;
    }
    :global(.ant-radio-wrapper span.ant-radio + *) {
      padding-inline-end: 0;
    }
  }
}

.payoutType {
  padding: 24px 0 52px;

  .radioGroup {
    min-height: auto;
  }
}
