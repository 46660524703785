.wrapper {
  max-width: 100%;

  :global(.ant-table-column-title) {
    flex: unset;
  }

  :global(.ant-table-thead .ant-table-filter-column) {
    justify-content: unset;
    align-items: flex-start;
  }

  :global(.ant-dropdown-trigger.ant-table-filter-trigger) {
    border-radius: 8px;
    margin-left: 8px;
    margin-top: 0;

    svg: {
      margin-bottom: 11px; // margin between the button and arrange popup
    }

    &:hover {
      background: none;
    }
  }
}

.wrapper_extended {
  composes: wrapper;
  max-width: calc(100vw - 66px);
}

.hiddenHeaderCell {
  background: #cce1e9;
}

.borderRight {
  border-right: 2px solid #cce1e9;
}

.borderRightLight {
  border-right: 1px solid #e5f0f4;
}

.textAlignRight {
  text-align: right;
}

.expandBodyCell {
  padding: 0 !important;
}

.headerCellTitle {
  white-space: pre;
}

.allowWrap {
  & span {
    white-space: pre-wrap;
  }
}

.priorityInDemandArea {
  & div {
    width: 122px;
  }
}

.selectionType {
  & div {
    width: 155px;
  }
}

.titleAndButtonWrap {
  align-items: flex-start !important;
}

.markedCell {
  color: #da1b5e;
  font-weight: 700;
}

.picture {
  border-radius: 8px;
  width: 48px;
  height: 48px;
}

.productActionsCell {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 20px;
}

.name {
  display: flex;
  align-items: center;
  gap: 8px;
}

.firstLetter {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #cce1e9;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
}
