@import './table';
@import './mixins';

body {
  margin: 0;
  position: relative;
  font-family: SimonKucher, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

span,
div,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

.flex-x-c {
  display: flex;
  justify-content: center;
}

 //scrollbar
//::-webkit-scrollbar {
//  width: 8px;
//  height: 8px;
//}
//
//::-webkit-scrollbar-track {
//  background: #f1f1f1;
//  border-radius: 2px;
//}
//
//::-webkit-scrollbar-thumb {
//  background: #888;
//  border-radius: 2px;
//}

#root {
  display: flex;
  min-height: 100%;
  position: relative;
}

.white_color {
  color: #fff;
}

.primary_color {
  color: #da1b5e;
}

.secondary_color {
  color: #5c5c6e;
}

.bold {
  font-weight: 700;
}

.uppercase {
  text-transform: uppercase;
}

// layout
.ant-layout {
  background: transparent;
}

// button
.ant-btn-primary {
  &:disabled,
  &[disabled] {
    background-color: #f4bace;
    color: #fff;
    border: none;
    cursor: not-allowed;
  }
}

// table
.ant-table-thead
  > tr
  > th:not(:last-child, .ant-table-selection-column, .ant-table-row-expand-icon-cell, [colspan])::before {
  display: none;
}

.ant-table-wrapper .ant-table-thead > tr > th {
  padding: 0;
  background: #e5f0f4;
  overflow: hidden;
  border: none;
}

.ant-table-wrapper .ant-table-tbody > tr > td {
  padding: 14px 24px;
  vertical-align: top;
}

.ant-table-tbody > tr.ant-table-row:hover {
  filter: drop-shadow(0 4px 8px rgb(0 0 0 / 15%));
}

.ant-table-wrapper .ant-table-tbody > tr.ant-table-row:hover > td {
  background-color: transparent;
}

.ant-table-wrapper
  .ant-table:not(.ant-table-bordered)
  .ant-table-tbody
  > tr.ant-table-row:hover
  > td:first-child {
  border-radius: 0;
}

.ant-table-wrapper
  .ant-table:not(.ant-table-bordered)
  .ant-table-tbody
  > tr.ant-table-row:hover
  > td:last-child {
  border-radius: 0;
}

.ant-table {
  filter: drop-shadow(0 4px 20px rgb(0 0 0 / 10%));
}

// modal
.ant-modal .ant-modal-content {
  padding: 34px 24px 20px;
}

// modal mask
.ant-modal-root .ant-modal-mask {
  background: rgb(0 0 0 / 80%);
}

// modal container
.ant-modal-wrap {
  container-type: inline-size;
}

.ant-modal .ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
  margin: 0;
}

// form item
.ant-form-item-feedback-icon-success {
  & > span {
    background: no-repeat center url('../../assets/icons/checkmark-circle.svg');

    svg {
      display: none !important;
    }
  }
}

.ant-form-item-feedback-icon-warning {
  & > span {
    background: no-repeat center url('../../assets/icons/warning.svg');

    svg {
      display: none !important;
    }
  }
}

.ant-form-item-feedback-icon-error {
  & > span {
    background: no-repeat center url('../../assets/icons/alert.svg');

    svg {
      display: none !important;
    }
  }
}

.ant-form-item-feedback-icon-validating {
  svg {
    fill: #0092a3;
  }
}

*[class*='ant-form-item-feedback-icon'] {
  cursor: initial;

  & > span {
    height: 16px;
    width: 16px;
  }
}

// React select
.react-select-multiselect {
  caret-color: transparent;
}

.react-select__control {
  color: transparent;
}

.react-select__indicator-separator {
  display: none;
}

.react-select__multi-value__remove {
  cursor: pointer;
}

// ant radio

.ant-radio-wrapper {
  color: #191919;

  .ant-radio-inner {
    border-color: #191919;

    &::after {
      background: #191919;
    }
  }
}

.ant-radio-wrapper .ant-radio-checked {
  .ant-radio-inner {
    background-color: transparent;
    border-color: #191919;

    &::after {
      background: #191919;
    }
  }
}

.ant-radio-wrapper:hover {
  color: #da1b5e;

  .ant-radio-inner {
    border-color: #da1b5e;

    &::after {
      background: #da1b5e;
    }
  }
}

.ant-radio-wrapper:active .ant-radio-inner {
  border-color: #e1497e !important;

  &::after {
    background: #e1497e !important;
  }
}

.ant-radio-wrapper-disabled {
  color: #9696a2 !important;

  .ant-radio .ant-radio-inner {
    border-color: #858899 !important;

    &::after {
      background: #858899 !important;
    }
  }
}

.ant-upload-select {
  width: 100%;
}

// pagination
.ant-pagination .ant-pagination-item-active a,
.ant-pagination .ant-pagination-item-active:hover a {
  color: #191919;
}

.ant-pagination .ant-pagination-item-active,
.ant-pagination .ant-pagination-item-active:hover {
  font-weight: 700;
  border-color: #191919;
}

.ant-pagination .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
.ant-pagination .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  color: #191919;
}

.custom-ant-checkbox-group-wrapper .ant-checkbox-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

// checkbox
.ant-checkbox-checked,
.ant-select-tree-checkbox-checked {
  .ant-checkbox-inner,
  .ant-select-tree-checkbox-inner {
    background: no-repeat center url('../../assets/icons/CheckmarkIcon.svg') !important;
  }
}

.ant-select-tree-switcher {
  display: none;
}

// checkbox
.ant-checkbox,
.ant-select-tree-checkbox {
  margin: 0 !important;

  .ant-checkbox-inner,
  .ant-select-tree-checkbox-inner {
    transition: 0.1s ease all !important;
    background-color: unset !important;
    border-width: 1px !important;
    border-color: #191919 !important;
    height: 16px !important;
    width: 16px !important;
    border-radius: 2px !important;

    &::after {
      display: none !important;
    }

    &:hover {
      border-color: #ba1750 !important;
    }
  }

  &::after {
    border-radius: 2px !important;
    border-width: 1px !important;
  }

  *[class*='checkbox-inner'] {
    border-width: 1px;
    border-color: #191919;
    height: 16px !important;
    width: 16px !important;
    border-radius: 2px !important;
    background-color: unset !important;

    &:hover {
      border-color: #ba1750 !important;
    }
  }
}

.ant-typography-secondary {
  color: #9696a2 !important;
}

.ant-card-head-title {
  overflow: visible !important;
}

// Global datepicker
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: #da1b5e;
}

.container-type {
  container-type: inline-size;
}

.ant-notification {
  .ant-notification-notice {
    width: 266px;
    padding: 24px 16px;

    .ant-notification-notice-close {
      top: 8px;
      inset-inline-end: 8px;
      width: 16px;
      height: 16px;
    }

    .ant-notification-notice-content {
      font-weight: 700;
    }
  }
}
