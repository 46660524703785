.wrapper {
  width: 60px;

  & .icon {
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
  }
}

