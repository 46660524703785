.multiValue {
  background: #ececee;
  padding: 7px 10px 7px 8px;
  font-size: 12px;
  border-radius: 8px;
  display: flex;
  font-weight: 800;
  color: rgb(25 25 25);
  align-items: center;
  height: 100%;
  margin: 0;

  &:last-child {
    margin-right: 0;
  }

  .multiValueRemoveContainer {
    height: 14px;
    width: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 4px;
    padding: 0 4px;
    cursor: pointer;

    svg {
      width: 8px;
      height: 8px;
    }
  }
}

.listElement {
  padding-right: 0;
  margin-right: 10px;
}

.react-select__multi-value {
  background: #ececee !important;
  padding: 5px 0 5px 8px;
  font-size: 12px !important;
  border-radius: 8px !important;
  display: flex;
  font-weight: bold !important;
  color: rgb(25 25 25) !important;
  align-items: center;
  margin-right: 10px !important;
}

.react-select__multi-value__remove {
  background: none !important;

  svg {
    color: rgb(25 25 25) !important;
  }
}
