.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 16px;
  margin-bottom: 28px;

  & .title {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: -0.3px;
    margin: 0;
  }
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 16px;

  & > div {
    flex: 1;
  }
}