.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  height: calc(100dvh - 66px);
  width: 1440px;
  margin: 16px auto 0;
  container-type: inline-size;
  overflow: auto;
  box-shadow: 0 4px 20px 0 #0000001a;
}

.tablet {
  width: 1135px;
}

.mobile {
  width: 375px;
}

.main {
  flex: 1;
}
