.container {
  margin-inline: -16px;
  background-image: url("../../../../assets/images/house-new-desktop.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%;
  height: 344px;

  & .innerContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 342px;
    row-gap: 8px;
    padding: 22px 22px 20px;

    & .title {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      margin-top: 8px;
      color: #fff !important;
      font-size: 14px !important;
      text-align: center;
    }

    & .subTitle {
      display: block;
      margin: 4px auto 0;
    }


    & .houseTop {
      position: relative;
      width: 100%;
      height: 176px;
      display: flex;
      column-gap: 8px;

      & .houseTopLeft {
        position: relative;
        width: 50%;
        border-radius: 16px 0 16px 16px;
        padding-top: 61px;
        padding-inline: 8px;
        clip-path: polygon(20% 42%, 100% 0, 100% 100%, 0 100%, 0 60%, 1% 56%, 5% 51%, 5% 51%);
      }

      & .houseTopRight {
        width: 50%;
        border-radius: 0 16px 16px 16px;
        padding-top: 61px;
        padding-inline: 8px;
        clip-path: polygon(80% 42%, 0 0, 0 100%, 100% 100%, 100% 60%, 99% 56%, 95% 51%, 95% 51%);
      }

      & .iconWrapper {
        display: flex;
        justify-content: center;
        border-radius: 0 0 16px 16px;
        margin-top: -61px;
        padding: 61px 8px 16px;

        & .icon {
          width: 40px;
          height: 40px;
        }
      }
    }

    & .houseBottom {
      width: 100%;
      height: 125px;
      display: flex;
      column-gap: 8px;

      & .houseBottomLeft {
        width: 50%;
        border-radius: 16px 16px 16px 32px;
        padding-inline: 8px;
        padding-bottom: 8px;
        overflow: hidden;
      }

      & .houseBottomRight {
        width: 50%;
        border-radius: 16px 16px 32px 16px;
        padding-inline: 8px;
        padding-bottom: 8px;
        overflow: hidden;
      }

      & .iconWrapper {
        display: flex;
        justify-content: center;
        padding: 16px 8px;
        border-bottom-right-radius: 16px;
        border-bottom-left-radius: 16px;

        & .icon {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
}