.container {
  background-color: #fff;

  & .button {
    border-radius: 24px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & .description {
    line-height: 20px;
  }
}
.buttonsWrapper {
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 8px;
  margin-bottom: 48px;
  margin-top: 32px;
}
.footer {
  width: 100%;
  display: flex;
  gap: 16px;
  .footerButton {
    width: 50%;
    border-radius: 8px !important;
  }
}
.buttonSelected {
  background: #444444 !important;
  color: #fff !important;
  border-color: #444444 !important;
}
