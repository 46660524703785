.upload_box {
  display: flex;
  align-items: center;
  gap: 8px;
  height: 24px;
  cursor: pointer;

  &_icon {
    fill: red !important;
  }

  &_info_block {
    display: flex;
    align-items: center;
    margin-left: 8px;
  }

}

.disabled {
  opacity: 0.4;
}