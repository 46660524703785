.container {
  margin-top: 35px;

  & .tooltipIcon {
    color: #da1b5e;
  }

  & .subTitle {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: -0.24px;
  }

  & .header {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
  }
}

