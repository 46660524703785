.trackY {
  background-color: transparent !important;
  right: 5px !important;
  z-index: 1000;
  width: 8px !important;
}

.MyAwesomeScrollbarsWrapper {
  inset: 0px 0px 0px 0px !important;
}

.tHuMbY {
  display: block;
  background-color: #19191966 !important;
}