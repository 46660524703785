@import '../../../../app/styles/mixins';

.wrapper {
  padding: 24px 16px;
  background-color: #f7f7f8;
  border-radius: 8px;

  @include for-phone-only {
    padding: 16px;
  }

  .title {
    display: inline-block;
    line-height: 22px;
  }

  .statusName {
    display: inline-block;
    margin: 0 0 16px;
    color: currentColor !important;
  }

  .pointsSection {
    margin-bottom: 3px;
    display: flex;
    align-items: baseline;
    min-width: 300px;
  }

  .currentPoints {
    display: flex;
    align-items: center;
    font-size: 48px;
    line-height: 65px;
    color: #444;
    font-weight: bold;

    @include for-phone-only {
      font-size: 35px;
      font-weight: 700;
      line-height: 38px;
      color: #444;
    }
  }

  .points {
    font-size: 24px;
    line-height: 33px;
    color: #858899;

    @include for-phone-only {
      font-size: 16px;
    }
  }

  .description {
    display: flex;
    gap: 4px;
    align-items: center;
    margin-bottom: 28px;
    color: #444;
    line-height: 22px;

    @include for-phone-only {
      margin-bottom: 0;
    }
  }

  .button {
    width: 256px;

    @include for-phone-only {
      display: none;
    }
  }

  .bold {
    font-weight: 700;

    @include for-phone-only {
      font-weight: normal;
    }
  }
}
