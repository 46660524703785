@import '../../../../app/styles/mixins';

.wrapper {
  width: 100%;
  min-width: 100px;
  display: flex;
  flex-direction: column;
  color: #333;
  position: relative;
  z-index: 1;

  .header {
    display: flex;
    margin-bottom: 10px;
  }

  .icon {
    height: 16px;
    width: 16px;
    margin-right: 6px;
  }

  .points {
    margin-left: auto;
    line-height: 16px;
    font-size: 12px;
    word-break: keep-all;
    color: currentColor;
  }

  .sublevels {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    width: 100%;
  }

  .sublevel {
    width: 100%;
    min-width: 25px;
    height: 12px;
    border-radius: 8px;
    box-shadow: 1px 2px 4px 0 #0000004d inset;
  }
}

.coveredSublevel {
  background: currentColor;
  box-shadow: 1px 2px 4px 0 #0000004d inset;
}

.wishedSublevel {
  background: repeating-linear-gradient(
    -45deg,
    currentColor,
    currentColor 5px,
    rgb(255 255 255 / 25%) 5px,
    rgb(255 255 255 / 25%) 10px
  );
}

.mobileWrapper {
  min-width: 100%;
  color: #333;

  .active {
    background-color: #f5f5f6;
    box-shadow: 0 1px 11px 0 #0003;
    color: #444;
  }

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }

  .header {
    display: flex;
    margin-bottom: 8px;
  }

  .title {
    display: block;
    margin-right: 8px;
    font-weight: bold;
    line-height: 16px;
    font-size: 14px !important;
    word-break: keep-all;
    color: #858899;
  }

  .icon {
    height: 16px;
    width: 16px;
  }

  .mobilePoints {
    display: flex;
    align-items: center;
    margin-left: auto;
    word-break: keep-all;
    color: currentColor !important;
  }

  .mobileSublevels {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
  }

  .mobileSublevel {
    width: 100%;
    min-width: 25px;
    height: 12px;
    border-radius: 8px;
    box-shadow: 1px 2px 4px 0 #0000004d inset;
  }
}

.sublevelsWrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  border-radius: 8px;
  background: rgb(68 68 68 / 5%);
  margin-bottom: 9px;
  padding: 8px 10px;
}

.desktopPoints {
  transform: translate(-28px);
  margin-top: 2px;
  display: flex;
  align-items: center;
}

.active {
  color: #444;

  .title {
    color: #444;
  }
}

.title {
  color: #b3b3bb !important;
}
.titleActive {
  color: #444 !important;
}
