.container {
  width: 100%;
  height: 100dvh;
  background-image: url("./../../assets/images/project-deactivated.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & .textContainer {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;

    & .title {
      font-size: 40px;
      font-weight: 600;
      color: #fff;
      letter-spacing: -0.8px;
      margin-bottom: 25px;
    }

    & .subTitle {
      font-size: 62px;
      width: 700px;
      text-align: center;
      font-weight: 700;
      letter-spacing: -1.24px;
      color: #fff;
    }
  }

  & .shadow {
    position: absolute;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 965px;
    height: 340px;
    background: rgb(0 0 0 / 40%);
    mix-blend-mode: multiply;
    filter: blur(112px);
  }
}
