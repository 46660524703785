@import 'src/app/styles/mixins.scss';

.wrapper {
  display: flex;
  min-height: 120px;
  box-shadow: 0 4px 20px 0 #0000001a;
  min-width: 260px;
  background-color: white;
  white-space: break-spaces;

  .pictureWrapper {
    & > img {
      width: 56px;
      height: 56px;
      object-fit: cover;
    }
  }

  .textWrapper {
    display: flex;
    flex-direction: column;
    padding: 16px;
    min-width: 216px;
  }

  .title {
    margin-bottom: 8px;
    line-height: normal;
  }

  .description {
    line-height: normal;
    white-space: break-spaces;
  }
}
