.space {
  display: flex;
  align-items: baseline;
  gap: 12px;
}

.formItem {
  margin: 0;
}

.formSection {
  margin: 0;
  width: 240px;

  > div {
    min-height: 64px;
  }
}

.label {
  font-weight: 700;
}

.trashButton {
  width: 16px;
}

.trashIcon {
  & path {
    fill: #5c5c6e;
  }
  &:hover path {
    fill: #da1b5e;
  }
}

.select {
  width: 70px;
}
