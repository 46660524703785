.root {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .intro {
    display: flex;
    align-items: center;


    .image {
      margin-right: 22px;
    }

    .divider {
      margin-right: 46px;
      width: 6px;
      height: 132px;
      background-color: #CFCFD4;
    }

    .nameSection {
      margin-right: 142px;

      .name {
        font-size: 51px;
        color: #5C5C6E
      }

      .bold {
        font-weight: bold;
        font-size: 61px;
        color: #191919
      }
    }

  }


}


