.header {
  & .title {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 24px;
  }

  & .subTitle {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
  }
}

.row {
  display: flex;
  justify-content: space-between;
  column-gap: 16px;
  margin-top: 12px;

  & > div {
    flex: 1;
  }
}