.root {
  :global(.ant-modal-close span) {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  :global(.ant-modal-content) {
    padding: 24px !important;
    border-radius: 4px;
  }

  :global(.ant-modal-footer) {
    margin-top: 0px;
  }

  .title {
    margin-bottom: 16px;
  }

  .subTitle {
    margin-bottom: 16px;
  }
}

.footer {
  gap: 16px;
  display: flex;
  align-items: center;
  flex: 1;

  > button {
    width: 100%;
  }
}
