.root {
  display: flex;
  align-self: center;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px !important;
  transition: 0.2s ease all;
  z-index: 2;
  color: #9696a2 !important;
  border: none !important;
  width: auto !important;

  &[class~='asLabel'] {
    top: -10px;
    transform: translateY(0%);
    left: 12px !important;
    font-size: 12px !important;
    background: white !important;
    padding: 0 2px !important;
    z-index: 2;
  }
}

.rootSmall {
  composes: root;
  top: 5px;
  transform: translateY(0%);
  font-size: 12px !important;
}
