.root {
  margin-top: 5px;
}

.modal {
  min-width: 664px;

  .form {
    display: flex;
    flex-direction: column;
  }
}

.subtitle {
  margin-bottom: 13px;
  font-weight: bold;
  line-height: 16px;
  font-size: 16px;
}
