@import '../../app/styles/mixins';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100dvh;
  padding-top: 40px;
  padding-bottom: 40px;


  overflow: hidden;
}
