.wrapper {
  display: flex;
  min-height: 78px;
  padding: 16px 24px;
}

.content {
  display: flex;
  flex: 1;
  align-items: flex-start;
  flex-flow: column wrap;
  vertical-align: top;
}

.root {
  display: flex;
  align-items: center;
}

.title {
  white-space: pre;
}

.btn {
  display: flex;
  border: none;
  background: none;
  margin: 0;
  padding: 0;
  outline: 0;
  cursor: pointer;
}

.sortBtn {
  composes: btn;
  margin: 2px 0 0 16px;
}

.labelRoot {
  display: flex ;
  margin-top: 8px;
  align-items: center;

  & > button {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 16px;
    min-height: 16px;
    opacity: 0;
    border-radius: 50%;

    svg {
      padding: 1.4px;
    }
  }

  & > span {
    font-size: 16px;
    margin-right: 16px;
    word-break: normal;
    font-weight: 400;
  }

  & > * {
    transition: all 0.75s;
  }

  &:hover {
    button {
      opacity: 1;
      border: none;
    }

    & span {
      color: #ba1750;
    }
  }
}

.filterValuesPopup {
  animation-duration: 0.1s;

  :global(.ant-popover-inner) {
    background: linear-gradient(287.86deg, #282828 0%, #3F3F53 48.28%, #141414 98.62%);
    border-radius: 8px;
    padding: 8px;
  }

  :global(.ant-popover-title) {
    color:white;
    margin-bottom: 12px;
  }

  :global(.ant-popover-arrow) {
    display: none;
  }

  :global(.ant-popover-title, .ant-popover-inner-content) {
    color: #fff;
    min-width: unset;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: flex;
      align-items: center;
      padding: 4px 0;
      color:white;

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0;
      }

      & > * {
        transition: all 0.1s;
      }

      &:hover {
        & > button {
          opacity: 1;
        }

        &,
        & > span {
          color: #e9769e;
        }
      }

      button {
        margin-left: 8px;
        min-width: 16px;
        min-height: 16px;
        opacity: 0;
        border: 1.5px solid currentColor;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          padding: 1.4px;
        }
      }
    }
  }
}
