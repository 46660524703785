.header {
  display: flex;
  align-items: center;
  height: 60px;
  padding-inline: 96px 88px;
  position: sticky;
  top: 0;
  z-index: 1000;

  &_wrap {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    align-items: center;
  }

  &_logo_wrap {
    display: flex;
    align-items: center;
    max-width: 250px;
    cursor: pointer;
  }

  &_logo_image {
    height: 44px;
    object-fit: cover;
  }
}

.withShadow {
  box-shadow: 0 4px 20px rgb(0 0 0 / 10%);
}

.navigation {
  height: 100%;
  margin-left: auto;
  display: flex;
  align-items: center;
  line-height: initial;
}

.lastUpdate {
  margin-right: 40px;
  color: #768187 !important;
}

.baseLink {
  width: 150px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: none;
}

.link {
  composes: baseLink;

  &:hover {
    background: color-mix(in srgb, currentColor 10%, transparent);
  }
}

.activeLink {
  composes: baseLink;

  > * {
    color: white !important;
  }
}

.profileWrapper {
  margin-left: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  & .faqButton {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;

    & > svg {
      fill: #000000;
      transition: 0.3s;
    }

    &:hover {
      border-color: var(--hoverColor);
    }

    &:hover > svg {
      fill: var(--hoverColor)
    }
  }
}

.profileButton {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profileIcon {
  width: 20px;
  height: 20px;

  path {
    fill: white;
  }
}
