.wrapper {
  display: flex;
  align-items: center;
}

.iconWrapper {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #cfcfd4;
  border-radius: 5px;
  margin-right: 10px;
}

.icon {
  width: 24px;
  height: 24px;
}
