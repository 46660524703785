.table_btn {
  width: max-content;
  height: 24px;
  margin-bottom: 16px;
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 12px;

  &__lg {
    width: 235px;
  }

  &__tab {
    margin-bottom: 0;
  }
}

.row-light {
  background-color: rgba(255, 255, 255, 0.7);
}

.row-dark {
  background-color: #f2f7f9;
}

.icon_btn {
  height: 16px;
  width: 16px;
  padding: 0;
  border: none;
  cursor: pointer;
  background: transparent;
  outline: none;

  &:disabled span, &:disabled {
    border: none;
    cursor: not-allowed;
    color: #858899 !important;
  }

  &:disabled svg {
    fill: #858899;
  }
}

