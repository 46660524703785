@import '../../../../app/styles/mixins';

.mainContainer {
  position: relative;
  height: auto;
  width: 704px;
}

.section {
  position: relative;
  background-size: cover;
  background-position: left;
  background-repeat: no-repeat;
  min-height: 703px;
  align-items: center;
  justify-content: center;
  gap: 30px;
  background-color: linear-gradient(68deg, rgb(0 0 0 / 20%) 0%, rgb(0 0 0 / 0%) 64.29%);
  display: flex;
  flex-wrap: nowrap;

  @include for-desktop-down {
    flex-wrap: wrap-reverse;
  }

  padding: 40px 24px;
}

.headlineBlock {
  position: relative;
  max-width: 538px;
  margin-right: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;

  @include for-desktop-up {
    min-height: 400px;
    margin-right: 0;
    margin-bottom: -20px;
  }
}

.arrowIcon {
  position: absolute;
  right: 80%;
  top: -75px;
  z-index: 100;

  path {
    fill: var(--primary-color);
  }

  @include for-desktop-down {
    left: 120%;
    top: 60%;
    transform: rotateY(180deg) rotateZ(320deg);
  }
}
