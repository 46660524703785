.itemContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 24px;
  padding-top: 24px;
  border-bottom: 1px solid #191919;
  row-gap: 8px;

  &:last-child {
    border: none;
  }

  & .nameField {
    width: 200px;
  }

  & .urlField {
    width: 100%;
  }

  & .titleContainer {
    display: flex;
    column-gap: 16px;
    justify-content: space-between;
    align-items: flex-start;

    & svg {
      fill: #5C5C6E;

      &:hover {
        fill: red;
        transition: 0.2s;
      }
    }
  }

  & .urlContainer {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    column-gap: 16px;

    span {
      flex: 1;
      margin-right: 8px;
      word-break: break-word;
    }

    & .button {
      display: flex;
      justify-content: center;
      align-items: center;

      & > span {
        margin: 0;

        & > span {
          margin: 0;
        }
      }
    }
  }
}

.listContainer {
  & .addButton {
    display: flex;
    column-gap: 8px;
    margin-top: 16px;
    margin-bottom: 16px;
    align-items: center;
    width: max-content;

    & .buttonTitle {
      color: #DA1B5E;
      font-size: 16px;
      font-weight: 700;
    }

    & .addIcon {
      fill: #da1b5e;
    }
  }
}

