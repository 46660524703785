.uploadLabel {
  margin-bottom: 0;
}

.cardBodyWrapper {
  padding: 24px 24px 0;
}

.text {
  font-weight: 700;
  display: block;
  margin-bottom: 16px;
}
