.wrapper {
  margin: 20px 0 40px;

  & > div {
    margin-bottom: 6px;
  }
}

.rowWrapper {
  display: flex;
  justify-content: space-between;
  gap: 16px;

  & > div {
    width: 100%;
  }
}

.text {
  display: block;
  margin-bottom: 24px;
  font-weight: 700;
}

.editTitle {
  margin-bottom: 24px;
}

.paragraph {
  margin-bottom: 24px;
}

.bold {
  font-weight: 700;
}

.uploadLabel {
  margin-bottom: 0;
  width: auto;

  > div > div {
    max-width: 460px;
  }
}
