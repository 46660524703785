.root {
  display: flex;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  outline: 0;
  cursor: pointer;
  color: #da1b5e;
}

.disabled {
  color: #858899;
  cursor: not-allowed;
}

.text {
  font-weight: 700;
  font-size: inherit;
  color: currentColor;
}
