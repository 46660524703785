.inputDate {
  height: 24px !important;

  & > input {
    padding: 5px 30px 5px 16px;
  }
}

.root {
  z-index: 1000;

  :global(.react-datepicker__header) {
    background-color: #f9f9fa;
    border-bottom: 1px solid #5c5c6e;
    padding: 12.5px 15px;
  }

  :global(.react-datepicker) {
    border: 1px solid #5c5c6e;
    border-radius: 8px;
    overflow: hidden;
  }

  :global(.react-datepicker__month) {
    padding: 11px;
    margin: 0;
  }

  :global(.react-datepicker__day-names) {
    margin: 0;
  }

  :global(.react-datepicker__day-name) {
    font-family: SimonKucher, serif;
    font-weight: 700;
    width: 33px;
    margin: 3px;
    text-transform: capitalize;
    line-height: 1;
  }

  :global(.react-datepicker__day) {
    font-family: SimonKucher, serif !important;
    font-weight: 400;
    font-size: 14px !important;
    line-height: 1;
    color: #191919 !important;
    outline: none;
    border: none;
    letter-spacing: -0.15;
    height: 33px !important;
    width: 33px !important;
    margin: 3px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      border-radius: 4px !important;
      background-color: #f4bace;
      color: #fff !important;
    }

    &:global(.react-datepicker__day--outside-month) {
      color: #858899 !important;
    }

    &:global(.react-datepicker__day--in-selecting-range) {
      background-color: #f4bace;
    }
  }

  :global(.react-datepicker__day--disabled) {
    color: #ccc !important;
  }

  :global(.react-datepicker__day--keyboard-selected) {
    background-color: #f4bace !important;
    border-radius: 4px !important;
  }

  :global(.react-datepicker__month-container) {
    display: flex;
    flex-direction: column;
  }

  :global(.react-datepicker__week) {
    display: flex;
  }

  :global(.react-datepicker__day--in-range) {
    z-index: 10 !important;
    position: relative;
    height: 1.7rem;
    color: #191919 !important;
    font-weight: 400;

    &,
    &:hover {
      background-color: #f4bace;
    }
  }

  :global(
  .react-datepicker__day--selected,
  .react-datepicker__day--selecting-range-start,
  .react-datepicker__day--selecting-range-end,
  .react-datepicker__day--range-start,
  .react-datepicker__day--range-end
) {
    height: 1.7rem;
    border-radius: 4px !important;
    color: #fff !important;
    font-weight: 700 !important;
    border: none;

    &,
    &:hover {
      background-color: #da1b5e !important;
    }
  }

  :global(.react-datepicker__navigation) {
    outline: none;
  }

  :global(.react-datepicker__day--today) {
    font-weight: 700;
  }

  :global(.calendar) {
    :global(.react-datepicker__current-month) {
      width: 171px;
      height: 32px;
      margin: 8px auto 0 !important;
      line-height: 32px;
      border-radius: 99px;
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 0.12px;
      color: #191919 !important;
    }

    :global(.react-datepicker__navigation--next) {
      right: 75px !important;
      top: 25px !important;
      outline: none !important;
      border-right-color: red !important;
    }

    :global(.react-datepicker__navigation--previous) {
      left: 75px !important;
      top: 25px !important;
      outline: none !important;
    }
  }

  :global(.react-datepicker__current-month) {
    font-weight: 700;
    font-size: 16px;
    letter-spacing: -0.15px;
    color: #191919 !important;
  }

  :global(.react-datepicker__triangle) {
    display: none;
  }

  :global(.react-datepicker__month-wrapper) {
    height: 70px !important;
  }

  :global(.react-datepicker__month-text--keyboard-selected) {
    background-color: #da1b5e !important;

    &:hover {
      background-color: #ffcdd9 !important;
    }
  }

  :global(.react-datepicker__month-text) {
    padding: 15px 0;

    &:hover {
      background-color: #ffcdd9 !important;
    }
  }
}
