@import '../../../../app/styles/mixins';

.wrapper {
  display: flex;
  flex-direction: column;
  padding: 32px 24px;

  @include for-phone-only {
    padding: 24px 16px;
  }

  @include for-desktop-up {
    padding: 40px 88px;
  }
}

.sectionHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;

  @include for-tablet-down {
    margin-bottom: 16px;
  }

  @include for-phone-only {
    margin-bottom: 16px;
  }

  & .title {
    margin: 0;
  }
}

.cardsWrapper {
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(3, minmax(300px, 1fr));

  & > div {
    padding: 0;
  }

  & > div > div {
    width: 100%;
  }

  @include for-phone-only {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }

  @include for-desktop-up {
    grid-template-columns: repeat(3, minmax(300px, 1fr));
  }

  @container (width >= 1440px) {
    grid-template-columns: repeat(4, minmax(300px, 1fr));
  }

  @include for-tablet-landscape-up {
    grid-template-columns: repeat(3, minmax(300px, 1fr));
  }

  @include for-tablet-only {
    grid-template-columns: repeat(2, minmax(300px, 1fr));
  }

  gap: 16px;
}

.headerButton {
  color: #444;
  align-items: center;
  display: flex;

  @include for-phone-only {
    justify-content: center;
    border-radius: 8px;
    border: 1px solid #616161;
  }
}
