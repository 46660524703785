@import '../../../../app/styles/mixins';

.smallHouseContainer {
  position: absolute;
  right: 88px;
  top: 50px;
  display: flex;
  width: 120px;
  height: 120px;
  flex-wrap: wrap;
  z-index: 10;

  @include for-tablet-only {
    width: 70px;
    height: 70px;
    top: 22px;
    right: 53px;
  }

  & .smallHousePentagon {
    width: 100%;
    height: 62px;
    position: relative;
    column-gap: 2.4px;
    display: flex;
    clip-path: polygon(50% 0%, 100% 32%, 100% 100%, 0% 100%, 0% 32%);
    background: linear-gradient(0deg, rgb(255 255 255 / 90%) 0%, rgb(255 255 255 / 90%) 100%);

    @include for-tablet-only {
      height: 36px;
    }
  }

  & .smallHouseRectangle {
    margin-top: 2.7px;
    width: 100%;
    height: 55.5px;
    position: relative;
    column-gap: 2.4px;
    display: flex;

    @include for-tablet-only {
      height: 32.3px;
    }
  }

  & .smallHouseSquare {
    flex: 1 1;
    cursor: pointer;
    transition: 0.3s ease-in-out;
  }
}