.root {
  width: 100%;
  margin-bottom: 16px;
  box-shadow: 0 4px 20px rgb(0 0 0 / 10%);

  :global(.ant-card-head) {
    padding: 24px;
  }
  :global(.ant-card-body) {
    padding: 16px 24px;
  }
  :global(.ant-upload-wrapper) {
    display: flex;
  }
}

.titleWrapper {
  display: flex;
  margin-bottom: 16px;
}

.title {
  font-weight: 700;
  font-size: 20px;
}

.space {
  margin-bottom: 16px;
}

.iconsWrapper {
  min-height: 40px;
}
