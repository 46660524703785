.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12.5px;

  button {
    padding: 5px;
  }
}

.title {
  font-weight: 700;
}

.button {
  display: flex;
  border: none;
  background: none;
  margin: 0;
  padding: 0;
  outline: 0;
  cursor: pointer;
}
