@import '../../../../app/styles/mixins';

.wrapper {
  display: flex;
  padding: 32px 88px;
  gap: 16px;
  overflow: auto;

  @include hide-scrollbar;

  @include for-desktop-down {
    padding: 24px;
  }

  @include for-tablet-down {
    flex-wrap: wrap;
  }
}
