@import '../../../../app/styles/mixins';

.squareSectionContainer {
  position: relative;
  z-index: 100;
  overflow: hidden;
  will-change: transform;
}

.squareContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include for-desktop-up {
    height: 490px;
  }
  will-change: transform;

  height: 350px;
  background: linear-gradient(0deg, rgb(255 255 255 / 85%) 0%, rgb(255 255 255 / 85%) 100%);
}

.container {
  margin: 68px 8px 88px;
  width: 696px;
  min-height: 700px;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.bigHouseIcon {
  position: absolute;
  z-index: 0;
}

.topContainer {
  height: 100%;
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.topLeft {
  flex: 1 1 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 8px 8px 8px;
  gap: 8px;
  overflow: hidden;
  clip-path: polygon(0.5% 56.5%, 116% 0, 100% 100%, 0% 100%);
  position: relative;
  max-width: 297px;
  margin-left: 39px;
  z-index: 5;
}

.topLeftLine {
  width: 335px;
  height: 3px;
  background: red;
  position: absolute;
  top: 110px;
  left: 23px;
  transform: rotate(-30deg);
  z-index: 10;
}

.topRightLine {
  width: 338px;
  height: 3px;
  background: red;
  position: absolute;
  top: 111px;
  right: 23px;
  transform: rotate(30deg);
  z-index: 10;
}

.topLeftIcon {
  position: absolute;
  z-index: 1;
  top: 30px;
  left: 38px;
}

.topLeftItem {
  border-radius: 0 0 16px 16px;
  flex: 1 1 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  text-align: center;
  position: relative;
  z-index: 2;
  padding: 0 3px;
}

.topRight {
  flex: 1 1 100%;
  margin-top: 0;
  border-radius: 10%;
  display: flex;
  justify-content: space-between;
  padding: 0 8px 8px 8px;
  gap: 8px;
  background-repeat: no-repeat;
  background-size: cover;
  clip-path: polygon(-17% 0, 78% 46%, 188% 100%, 0% 100%);
  max-width: 297px;
  margin-right: 41px;
  position: relative;
  z-index: 5;
}

.topRightIcon {
  position: absolute;
  z-index: 1;
  top: 30px;
  right: 40px;
}

.topRightItem {
  border-radius: 0 0 16px 16px;
  flex: 1 1 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  text-align: center;
  position: relative;
  z-index: 2;
  gap: 10px;
  background: linear-gradient(0deg, rgb(255 255 255 / 85%) 0%, rgb(255 255 255 / 85%) 100%);
  padding: 0 3px;
}

.bottomContainer {
  height: 100%;
  display: flex;
  justify-content: space-between;
  gap: 18px;
  overflow: hidden;
}

.bottomLeft {
  flex: 1 1 100%;
  background: #fff;
  margin-left: 37px;
  margin-top: 20px;
  max-height: 214px;
  border-radius: 15px 15px 20px 20px;
  display: flex;
  justify-content: space-between;
  padding: 0 8px 8px 8px;
  gap: 8px;
  position: relative;
  z-index: 5;
}

.bottomLeftItem {
  border-radius: 0 0 16px 16px;
  flex: 1 1 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  text-align: center;
  position: relative;
  margin-top: -1px;
  padding: 0 3px;
}

.bottomRight {
  flex: 1 1 100%;
  margin-right: 42px;
  margin-top: 20px;
  max-height: 214px;
  border-radius: 15px 15px 20px 20px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  padding: 0 8px 8px 8px;
  gap: 8px;
  position: relative;
  z-index: 5;
}

.bottomRightItem {
  border-radius: 0 0 16px 16px;
  flex: 1 1 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  text-align: center;
  position: relative;
  overflow: hidden;
  margin-top: -1px;
  padding: 0 3px;
}

.icon {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  &_top {
    top: 75%;
    transform: translateY(-50%)
  }
}

.title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 3;
  text-align: center;
  hyphens: auto;
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 15px;
}
