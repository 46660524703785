.buttonWrapper {
  text-align: center;

  & .button {
    color: #DA1B5E;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  & .small {
    font-size: 12px;
  }

  & .large {
    font-size: 16px;
  }


  & .button:hover {
    color: #831038 !important;
  }

  & .button:disabled {
    color: #858899 !important;
  }

  & .button:active {
    color: #E1497E !important;
  }
}

