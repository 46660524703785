@import '../../../../../styles/mixins';

.modal {
  top: 0;
  margin-bottom: 0;
  padding-bottom: 0;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  @include for-phone-only {
    display: block;
    width: 100vw;
    height: 100dvh;
    margin: 0;
  }

  :global(.ant-modal-content) {
    @include for-phone-only {
      height: 100dvh;
      width: 100vw;
      border-radius: unset;
      overflow: hidden;
    }
  }
}
