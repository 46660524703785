.root {
  :global(.ant-modal-body) {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    & > span {
      text-align: center;
    }
  }

  :global(.ant-modal-content) {
    padding: 48px 24px 32px !important;
  }

  .icon {
    width: 32px;
    height: 32px;
  }
}
