@import '../../../../app/styles/mixins';

.wrapper {
  display: flex;
  flex-direction: column;
  padding: 40px 0 0;

  @include for-desktop-down {
    padding: 48px 0 0;
  }

  .title {
    padding: 0 88px;
    margin: 0;
    margin-bottom: 8px !important;
    cursor: pointer;

    line-height: 1.2;

    @include for-desktop-down {
      font-size: 20px !important;
      line-height: 27px;
      padding: 0 24px;
    }
  }

  .subTitle[class~='ant-typography'] {
    padding: 0 88px;
    margin: 0;
    line-height: 32px;
    margin-top: 0 !important;

    @include for-desktop-down {
      font-size: 14px !important;
      line-height: 19px;
      font-weight: 400 !important;
      padding: 0 24px;
    }
  }

  .offers {
    padding: 32px 88px 78px;
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 16px;
    overflow-x: auto;
    overflow-y: hidden;

    @include hide-scrollbar;

    @include for-desktop-down {
      padding: 20px 24px 56px;
    }
  }

  .offersThirdMoreWidgets {
    padding: 32px 88px 78px;
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    column-gap: 16px;

    @include hide-scrollbar;

    @include for-desktop-down {
      padding: 20px 24px 56px;
    }
  }
}
