.wrapper {
  margin-top: 24px;
  position: relative;

  & .buttonGroup {
    position: absolute;
    top: -45px;
    right: 0;
    display: flex;
    column-gap: 24px;

    & .submitButton {
      height: 24px;
      padding: 0 16px;
      font-weight: bold;
      font-size: 12px;
      line-height: 12px;
    }

    & .addConditionButton {
      color: #191919;
      border: 1px solid #191919;
      background: none;
    }
  }


}

.container {
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  width: 100%;
}