.root {
  width: 100%;
  margin-bottom: 16px;
  box-shadow: 0 4px 20px rgb(0 0 0 / 10%);

  :global(.ant-card-body) {
    padding: 0;
  }
}

.section {
  padding: 24px 24px 0;
}

.divider {
  margin: 0;
}
