@import '../../../../app/styles/mixins';

.wrapper {
  display: flex;
  flex-flow: row wrap;
  gap: 16px 12px;

  :global(.slick-list) {
    @include for-desktop-up {
      padding-inline: 84px;
    }

    @container (max-width: 1363px) {
      padding: 24px 84px;
    }

    @include for-tablet-only {
      padding-inline: 24px;
      padding: 24px 24px;
    }

    @include for-phone-only {
      padding: 24px 16px;
    }
  }

  > div {
    overflow: hidden;
  }

  & .gridContainer {
    display: flex;
    padding: 24px 88px;
    gap: 16px 12px;
    flex-wrap: wrap;
  }
}
