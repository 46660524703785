@import './../../app/styles/mixins';

.container {
  display: flex;
  width: 100%;
  max-height: 100%;
  height: 100dvh;

  @include for-desktop-down {
    justify-content: center;
    align-items: center;
    height: 100dvh;
  }
}
