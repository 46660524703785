@import 'src/app/styles';

.container {
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  & .form {
    padding: 0 16px;

    @include for-phone-only {
      max-height: calc(100dvh - 250px);
      overflow: auto;
      flex: 1;
      margin-right: -24px;
      padding: 0 24px 0 0;
    }
  }

  & .icon {
    align-self: flex-end;
  }

  & .title {
    margin-bottom: 32px;
    padding: 0 16px;
    white-space: nowrap;

    @include for-phone-only {
      padding: 0;
    }
  }

  .checkbox {
    margin-bottom: 24px;
    min-height: auto;
    width: 100%;

    &:nth-child(4) {
      margin-bottom: 16px;
    }

    &:global(.ant-form-item-has-error) {
      & span, a {
        color: #e64242!important;
        border-color: #e64242 !important;
      }
    }
  }

  .checkboxText {
    display: block;
    line-height: 18px;
    margin-bottom: 24px;
  }

  & .checkboxContent {
    align-items: flex-start;
    column-gap: 6px;

    & > div > span {
      vertical-align: top;
      line-height: normal;

      @include for-phone-only {
        font-weight: 400;
      }
    }
  }

  & .link {
    text-decoration: underline;
    color: #f18700;
    margin-right: 4px;
    margin-left: 4px;
    cursor: pointer;
    position: relative;
    z-index: 100;
  }

  & .bottomButtons {
    display: flex;
    width: 100%;
    column-gap: 16px;
    margin-top: 32px;
    background-color: #fff;

    @include for-phone-only {
      position: absolute;
      z-index: 100;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 24px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.23);
    }

    & > button {
      width: 100%;
    }
  }

  & .subText {
    display: block;
    margin-bottom: 16px;
  }

  & .questionButton {
    padding: 0;
    display: flex;
    align-items: center;
    margin-left: auto;
    height: auto;

    & .questionIcon {
      margin-right: 7px;
    }

    & .text {
      margin: 0;
    }
  }
}
