@import '../../../../app/styles/mixins';

.wrapper {
  display: flex;
  width: 100%;
  overflow: auto;

  @include hide-scrollbar;

  .divider {
    height: auto;
    margin: 0 12px;
    border-inline-start-style: dashed;
  }

  @include for-phone-only {
    flex-wrap: wrap;
    padding: 16px 16px 16px 12px;
    background-color: #f7f7f8;
    border-radius: 8px;
  }
}
