.root {
  display: flex;
  align-items: start;
  justify-content: space-between;
  margin-top: 15px;
  height: calc(100vh - 160px);

  & .ruleButtonContainer {
    display: flex;
    column-gap: 16px;
  }
}

.btn {
  height: 24px;
  padding: 0 8px;
  font-weight: bold;
  font-size: 12px;
}
