.primaryButton {
  position: relative;

  &:disabled {
    opacity: 0.8;
  }

  &:hover {
    background-color: color-mix(in srgb, var(--dynamic-color), #000 15%) !important;
  }
}

.secondaryButton {
  &:hover {
    background-color: color-mix(in srgb, var(--dynamic-color) 5%, transparent) !important;
  }
}

.wishlistButton {
  &:hover {
    background-color: color-mix(in srgb, var(--dynamic-color), #000 15%) !important;
  }
}


.spinner {
  position: absolute;
  right: 30px;
  top: 12px;

}