@import '../../../../app/styles/mixins';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 32px 24px;

  @include for-desktop-up {
    padding: 48px 88px 56px;
  }

  @include for-phone-only {
    padding: 32px 16px;
  }

  & .title {
    font-size: 32px;
    margin-bottom: 8px;
    line-height: 38px;
  }

  .subTitle {
    margin: 0 !important;
    line-height: 32px;
  }

  .button {
    width: 240px;
    align-self: center;
  }
}

.title {
  margin: 0;

  @include for-desktop-down {
    font-size: 20px !important;
    line-height: 27px;
  }
}
