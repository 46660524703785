@import './../../styles/mixins';

.root {
  display: flex;
  flex-direction: column;
  position: relative;
}

.main {
  flex: 1;
  position: relative;
}

.statusLevelContainer {
  position: sticky;
  bottom: 0;
  width: 100%;
  z-index: 1000;

  @include for-desktop-down {
    margin-top: -73px;
  }

  @include for-phone-only {
    margin-top: -50px;
  }
}
