.dropdownRender {
  display: flex;
  flex-direction: column;
  width: max-content;
  padding: 8px 0;
  background-color: #fff;
  border: 1px solid #9696a2;
  border-radius: 0;
  box-shadow: 0 4px 20px rgb(0 0 0 / 10%);
}

.button {
  display: flex;
  margin: 0;
  border: none;
  outline: 0;
  cursor: pointer;
  appearance: button;
  padding: 8px 16px;
  line-height: 19px;
  background: #fff;
  letter-spacing: -0.015em;
  text-align: left;
}

.disabledButton {
  color: #858899;
  cursor: not-allowed;
}

.danger {
  color: #da1b5e;
}

.spinner {
  margin-left: 6px;
}
