.profile {
  &_button {
    border-radius: 8px!important;
    height: 32px;
    min-width: 100px;
    padding: 0 16px;
    font-size: 14px!important;
    line-height: 32px;
  }

  &_link {
    font-size: 16px!important;
    letter-spacing: -0.16px;
    text-align: left;
  }

  &_dropdown {
    padding-top: 20px;

    &_wrapper{
      display: flex;
      flex-direction: column;
      width: 264px;
      padding: 16px 0 0;
      box-shadow: 0 4px 24px rgb(0 0 0 / 25%);
      background-color: white;
      border-radius: 8px;

    }
  }

  &_user {
    &_avatar {
      display: flex;
      padding: 4px 0 0;
      margin-right: 10px;
      background: #444;
    }

    &_block {
      display: flex;
      padding: 0 16px;
      margin-bottom: 18px;
    }

    &_info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 182px;
    }

    &_text {
      font-size: 14px;
      line-height: 19px;
      letter-spacing: -0.015em;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__bold {
      font-weight: 700;
    }

    &__light {
      color: #5c5c6e;
    }
  }

  &_hr {
    border: none;
    border-top: 1px solid #ececee;
    color: #ececee;
    height: 1px;
    width: 100%;
    margin: 1px 0;
  }

  &_dropdownItem{
    cursor: pointer;
    padding: 8px 16px;
    line-height: 19px;
    border: none;
    letter-spacing: -0.015em;
    text-align: left;
    font-weight: 300;

    &_danger{
      color:#da1b5e
    }
  }
}
