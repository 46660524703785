@import './../../../../app/styles/mixins';

.bonusItem {
  display: flex;
  flex-direction: column;
  padding: 16px 0;
  border-bottom: 1px solid #cfcfd4;

  & .expired {
    color: #858899 !important;
  }

  &:last-child {
    border-bottom: none;
  }

  & .imageContainer {
    margin-right: 24px;

    @include for-tablet-only {
      margin-right: 16px;
    }

    @include for-phone-only {
      margin-right: 16px;
    }

    & .image {
      display: block;
      width: 143px;
      height: 88px;

      @include for-phone-only {
        width: 88px;
        height: 88px;
        border-radius: 3px;
      }
    }
  }

  & .details {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: 24px;

    @include for-tablet-only {
      margin-left: 16px;
    }

    @include for-phone-only {
      margin-left: 0;
    }

    & .detailsLeftSide {
      display: flex;
      max-width: 45%;

      @include for-desktop-down {
        max-width: 40%;
      }

      @include for-tablet-down {
        max-width: 65%;
      }

      @include for-phone-only {
        max-width: 100%;
      }
    }

    & .bonusTitle {
      margin-bottom: 8px;
      align-self: center;

      @include for-desktop-down {
        font-size: 16px !important;
      }

      @include for-phone-only {
        display: block;
        font-size: 14px !important;
        text-wrap: wrap;
        max-width: 60%;
      }
    }

    & .bonusName {
      display: block;
      text-align: right;
      text-wrap: nowrap;
      margin-left: auto;
      font-size: 14px !important;

      @include for-phone-only {
        font-size: 12px !important;
        text-wrap: wrap;
        min-width: 80px;
        margin-bottom: 10px;
      }
    }

    & .description {
      line-height: 20px;
      margin: 0;

      @include for-tablet-down {
        margin-bottom: 16px;
      }
    }
  }
}

.headerWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgb(97 97 97 / 30%);
  margin-bottom: 16px;
  padding-bottom: 16px;
}

.detailsRightSide {
  width: 50%;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;

  @include for-desktop-down {
    width: 55%;
  }

  @include for-tablet-down {
    width: 35%;
    justify-content: flex-end;
    align-items: flex-start;
  }

  @include for-phone-only {
    max-width: 0%;
  }
}

.radioWrapper {
  border-radius: 8px;
  border: 1px solid#444;
  padding: 2px;
  max-width: 146px;
  height: 24px;

  @include for-phone-only {
    height: 32px;
  }

  :global(.ant-radio-button-wrapper:not(:first-child)::before) {
    display: none;
  }

  :global(.ant-radio-button-wrapper) {
    background-color: transparent;
    border: none;
    height: 18px;
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: 11px;
    flex-flow: row nowrap;

    @include for-phone-only {
      height: 24px !important;
    }
  }

  :global(.ant-radio-group) {
    background-color: transparent;
    display: flex !important;
    flex-flow: row nowrap !important;
    gap: 8px;
    height: 24px;

    :global(.ant-radio-button-wrapper) {
      font-weight: 700;
      border-radius: 8px;
      border-inline-start: none;
      height: 18px;
    }
  }

  :global(.ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked) {
    background-color: #858899;
    color: #fff;
  }
}

.button {
  min-width: 144px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  font-weight: 700;
  font-size: 12px;

  @include for-phone-only {
    height: 32px;
  }
}

.buttonActivation {
  composes: button;
  background-color: transparent;
  border-color: #444;
  padding: 0 12px;
  & > span {
    color: #444 !important;
  }
}

.buttonActivated {
  composes: button;
  background-color: #00765f;
  & > span {
    color: #fff !important;
  }
}

.buttonShowMore {
  color: #444;
  font-size: 14px;
}

.buttonShowMoreWrapper {
  align-items: center;
  display: flex;
  justify-content: center;
}

.mobileButtonsWrapper {
  margin-top: 20px;
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
}
