.container {
  height: 40px;
  padding-inline: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #BA1750;

  & > span {
    color: #BA1750;
  }
}

.disabled {
  background-color: #F2F2F3;
}