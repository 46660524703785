.back_link {
  display: flex;
  align-items: center;
  margin-right: 100px;
  text-decoration: none;
  width: max-content;

  &_icon {
    color: #FFF;
    margin-right: 5px;
  }
}