.root {
  &:global(.icon_btn) {
    height: 100%;
    width: 24px;
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    background: #cce1e9;
    border-radius: 0 8px 8px 0;

    svg {
      width: 100%;
    }
  }
}

.rootExpanded {
  composes: root;

  &:global(.icon_btn) {
    border-radius: unset;
  }
}
