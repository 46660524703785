@import '../../../../app/styles/mixins';

.container {
  width: 690px;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../../../../assets/images/house-new-desktop.png");
  background-repeat: no-repeat;
  background-position: center center;
  row-gap: 16px;

  & .innerContainer {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    width: 610px;
    height: 550px;

    & .categoriesContainer {
      position: relative;
      z-index: 1;
      display: flex;
      width: 100%;
      column-gap: 8px;
      justify-content: space-between;
      padding-inline: 8px;

      & .categoryItemBottom {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;
        height: 156px;
        border-radius: 0px 0px 16px 16px;
        padding: 46px 8px 8px;
        cursor: pointer;

        &:first-child {
          border-top-left-radius: 4px;
        }

        &:last-child {
          border-top-right-radius: 4px;
        }
      }


      & .categoryItem {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;
        height: 274px;
        border-radius: 0px 0px 16px 16px;
        padding: 164px 8px 8px;
        cursor: pointer;
      }

      & .title {
        hyphens: auto;
      }

      & .icon {
        width: 34px;
        height: 34px;
        margin-bottom: auto;
      }
    }

    & .houseTop {
      position: relative;
      width: 100%;
      display: flex;
      column-gap: 16px;

      & .areaTitle {
        position: relative;
        z-index: 3;
        white-space: normal;
        color: #fff;
        text-align: center;
        line-height: 36px;
        display: block;
        margin-top: 4px;
      }

      & .houseTopLeftSide > div {
        clip-path: polygon(0% 61.3%, 100% -1%, 100% 100%, 0% 100%);

        @include for-tablet-only {
          clip-path: polygon(0% 61.4%, 100% -0.5%, 100% 100%, 0% 100%);
        }
      }

      & .houseTopRightSide > div {
        clip-path: polygon(0 -1%, 100% 61.3%, 100% 100%, 0% 100%);

        @include for-tablet-only {
          clip-path: polygon(0 -0.7%, 100% 61.4%, 100% 100%, 0% 100%);
        }
      }

      & .houseTopLeftSide {
        position: relative;
        width: 50%;
        height: 324px;

        & .frame {
          position: absolute;
          left: -1px;
        }
      }

      & .houseTopRightSide {
        position: relative;
        width: 50%;
        height: 324px;

        & .frame {
          position: absolute;
        }
      }
    }

    & .houseBottom {
      width: 100%;
      display: flex;
      column-gap: 16px;

      & .houseBottomLeftSide {
        width: 50%;
        height: 208px;
        border-radius: 16px 16px 16px 32px;
      }

      & .houseBottomRightSide {
        width: 50%;
        height: 208px;
        border-radius: 16px 16px 32px 16px;
      }

      & .areaTitle {
        position: relative;
        z-index: 3;
        white-space: normal;
        color: #fff;
        text-align: center;
        line-height: 36px;
        display: block;
        margin-top: 4px;
      }
    }
  }
}

.circleButton {
  width: 155px;
  height: 155px;
  border-radius: 50%;
  z-index: 100;
  position: absolute;
  top: -3%;
  left: 73%;
  background: linear-gradient(180deg, #60b11f 0%, #007829 100%);
  filter: drop-shadow(0 4px 20px rgb(0 0 0 / 10%));
  transform: rotate(14.244deg);
  color: #fff;
  font-size: 22px;
  font-weight: 700;
  white-space: normal;
  transition: 0.3s all;
  border: none;
  cursor: auto;

  &:hover,
  &:active {
    color: #fff !important;
  }
}