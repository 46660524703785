@import '../../../styles/mixins';

.header {
  display: flex;
  align-items: center;
  height: 50px;
  width: 100%;
  padding-inline: 16px 0;
  position: sticky;
  top: 0;
  z-index: 1000;

  &_wrap {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    align-items: center;
  }

  &_logo_wrap {
    display: flex;
    align-items: center;
    max-width: 135px;
  }

  &_logo_image {
    height: 32px;
    object-fit: cover;
  }

  .burgerBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    border-radius: 0;

    path {
      fill: white;
    }
  }
}

.withShadow {
  box-shadow: 0 4px 20px rgb(0 0 0 / 10%);
}

.headerVisible {
  z-index: 1001;

  @include for-phone-only {
    z-index: auto;
  }
}

.crossIcon {
  width: 14px;
  height: 14px;
  position: absolute;
  top: 18px;
  right: 25px;
  cursor: pointer;
  z-index: 1;
}

.drawerForEndUser {
  & [class~='ant-drawer-mask'] {
    @media screen and (width >= 600px) and (width <= 1199px) {
      margin-top: 50px;
    }
  }

  & [class~='ant-drawer-content-wrapper'] {
    @media screen and (width >= 600px) and (width <= 1199px) {
      top: 50px;
    }
  }
}

.drawerForPreview {
  & [class~='ant-drawer-mask'] {
    @include for-tablet-only {
      margin-top: 50px;
    }
  }

  & [class~='ant-drawer-content-wrapper'] {
    @include for-tablet-only {
      top: 50px;
    }
  }
}

.drawer {
  [class~='ant-drawer-body'] {
    position: relative;
    padding: 0 20px 0 28px;
  }

  [class~='ant-drawer-header'] {
    display: none;
  }

  .collapse {
    .link {
      height: 32px;
      margin-bottom: 16px;
      border: 0;

      > span:not(.categories) {
        font-weight: 400 !important;
      }
    }

    border-bottom: 1px solid #4443;
    border-radius: 0;

    [class~='ant-collapse-header-text'] > * {
      font-weight: 700 !important;
      position: relative;

      .activeIcon {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: -12px;
        transform: translateY(-50%);
        display: block;
      }
    }

    [class~='ant-collapse-header'] {
      padding: 0;
      height: 54px;
      align-items: center;
    }

    [class~='ant-collapse-content-box'] {
      padding: 0;
      padding-block: 0 !important;
    }
  }
}

.link {
  width: 100%;
  height: 50px;
  display: flex;
  position: relative;
  align-items: center;
  padding: 0;

  > span:not(.categories) {
    font-weight: bold !important;
  }

  &:not(:last-child) {
    border-bottom: 1px solid #4443;
    cursor: pointer;
  }

  &:hover {
    color: inherit;
  }

  .activeIcon {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: -12px;
    transform: translateY(-50%);
    display: none;
  }
}

.activeLink {
  composes: link;

  .activeIcon {
    display: block;
  }
}

.lastUpdateRow {
  height: 50px;
  line-height: 50px;
}

.lastUpdate {
  margin-right: 40px;
  font-size: 12px;
  color: #768187;
  cursor: auto;
}

.demandAreaIcon {
  display: block;
  width: 32px;
  height: 32px;
  margin-right: 6px;
  position: relative;

  &_content {
    width: 16px;
    height: 16px;
    margin: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.categories {
  margin-left: auto;
  flex-shrink: 0;
}

.user {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #4443;
  padding: 16px 0;

  &_avatar {
    display: flex;
    padding: 4px 0 0;
    background: #444;
    flex-shrink: 0;
  }

  &_info {
    display: flex;
    align-items: center;
  }
}

.profileWrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  & .container {
    margin-left: 16px;
    margin-right: 10px;
  }

  & .faqButton {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;

    & > svg {
      fill: #000000;
      transition: 0.3s;
    }

    &:hover {
      border-color: var(--hoverColor);
    }

    &:hover > svg {
      fill: var(--hoverColor)
    }
  }
}

:global(.ant-drawer-footer) {
  border-top: none!important;
}
