.form {
  display: flex;
  gap: 16px;
  height: 24px;
}

.datePickerFormItem {
  min-height: auto;
  width: 303px;

  :global(.react-datepicker-wrapper) {
    width: 100%;
  }

  :global(.react-datepicker__close-icon::after) {
    min-width: 12px;
    min-height: 12px;
    background-color: #9696a2;
  }

  :global(.ant-input-suffix) {
    display: none;
  }

  :global(.DatePicker) {
    input {
      height: 24px;
    }
  }
}

.select {
  width: 200px;
  height: 20px;
}
