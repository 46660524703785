.cardBodyWrapper {
  padding: 0 24px 0;
}

.text {
  font-weight: 700;
  display: block;
  margin-bottom: 16px;
}

.colorFormSection {
  margin-bottom: 0;
}

.colorFormItem {
  min-height: 64px !important;
}

.columns {
  display: flex;
  justify-content: space-between;
  gap: 16px;
}

.column {
  width: 50%;
}

.row {
  display: flex;
}