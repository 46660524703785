.wrapper {
  padding: 48px 21px;
  width: 448px;
  height: 435px;
  box-shadow: 0 4px 20px rgb(0 0 0 / 10%);

  & .title {
    text-transform: uppercase;
    margin-bottom: 26.5px;

    & .regularTitle {
      font-weight: normal;
    }
  }

  & .subTitle {
    display: block;
    color: #5C5C6E;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: -0.24px;
  }

  & .form {
    margin-top: 61.5px;
  }
}

