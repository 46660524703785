@import '../../../../app/styles/mixins';

.container {
  padding: 60px 60px 24px;
  min-width: 510px;
  display: flex;
  min-height: 100%;
  flex-direction: column;
  background-color: #fff;
  overflow-y: auto;

  @include specific-small-dDesktop {
    min-width: 400px;
  }

  @include for-tablet-only {
    min-width: 375px;
    padding: 24px;
    min-height: auto;
    border-radius: 8px;
    height: 600px;
  }

  @include for-phone-only {
    padding: 24px;
    min-width: 100%;
  }

  & .logo {
    max-width: 240px;
    height: 49px;
    object-fit: cover;

    @include for-desktop-down {
      max-width: 152px;
      height: 32px;
    }

    @include for-phone-only {
      max-width: 142px;
      height: 32px;
    }
  }

  & .main {
    margin-top: 88px;

    @include for-tablet-only {
      margin-top: 32px;
    }

    @include for-phone-only {
      margin-top: 32px;
    }

    & .title {
      margin-bottom: 40px;

      @include for-tablet-only {
        font-size: 24px !important;
        font-weight: 600 !important;
        margin-bottom: 32px;
      }

      @include for-phone-only {
        font-size: 24px !important;
        font-weight: 600 !important;
        margin-bottom: 32px;
      }
    }

    & .formItem {
      min-height: 72px;
    }

    & .sendButton {
      margin-top: 8px;
      width: 100%;
    }

    & .linkButtonsWrapper {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }

    & .resetPasswordButton {
      margin-top: 16px;
      padding: 0;
      height: 16px;
    }

    & .faqButton {
      padding: 0;
      height: 16px;
      display: flex;
      justify-content: flex-end;

      & > div {
        margin: 0;
      }
    }

    & .bottomButtons {
      margin-top: 56px;
      display: flex;
      flex-direction: column;
      row-gap: 16px;

      @include for-tablet-only {
        margin-top: 40px;
      }

      @include for-phone-only {
        margin-top: 40px;
      }
    }
  }

  & .videoFrame {
    position: relative;
    margin-top: 16px;

    & .iframe {
      width: 100%;
      height: 174px;
      border: none;
      border-radius: 10px;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1),
      0px 1px 3px rgba(0, 0, 0, 0.06);
    }

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: transparent;
      z-index: 2;
      cursor: pointer;
    }
  }

  & .linkButtonsContainer {
    display: flex;
    flex: 1;
    align-items: flex-end;
    width: 100%;
    margin-top: 40px;

    @include for-tablet-only {
      margin-top: 48px;
    }
  }
}

.containerWithNewDesign {
  min-width: 509px;

  @container (max-width: 1451px) {
    min-width: 449px;
  }
}