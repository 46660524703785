@import '../../../../app/styles/mixins';

.container {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  @include for-phone-only {
    align-items: flex-start;
  }

  .card {
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 528px;
    max-height: 900px;
    height: calc(100% - 54px);
    padding: 32px 40px 16px;
    align-items: flex-start;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0 4px 20px 0 rgb(0 0 0 / 10%);
    overflow: auto;

    @include for-desktop-down {
      max-width: 375px;
      max-height: 585px;
    }

    @include for-phone-only {
      max-width: unset;
      max-height: unset;
      height: 100%;
      border-radius: 0;
      margin: 0 auto;
    }

    &_content {
      display: flex;
      flex-direction: column;
    }

    .logo {
      width: auto;
      height: 40px;

      &__mail {
        height: 42px;
        margin: 0 auto;
      }
    }

    .title {
      font-size: 32px!important;
      line-height: 38px;
      letter-spacing: -0.64px;
      text-align: center;

      @include for-desktop-down {
        font-size: 24px!important;
        line-height: normal;
        letter-spacing: -0.48px;
      }
    }

    .text {
      font-size: 16px!important;
      color: #444!important;
      letter-spacing: -0.16px;
      text-align: center;
      margin: 24px 0;
    }

    .link {
      font-size: 16px!important;
      letter-spacing: -0.16px;
      text-decoration: underline;
    }

    .button {
      margin: 35px 0 0;
      display: flex;
      flex-direction: column;
    }
  }
}
