@import '../../../../app/styles/mixins';

.container {
  padding: 32px;
  width: 528px;
  height: 550px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 8px;
  align-self: center;
  justify-self: center;
  box-shadow: 0 4px 20px 0 rgb(0 0 0 / 10%);

  @include for-desktop-down {
    width: 375px;
    height: 560px;
    padding: 24px;
  }

  @include for-phone-only {
    width: 100%;
    height: 100dvh;
    border-radius: 0;
  }

  & .logo {
    max-width: 240px;
    height: 49px;
  }

  & .icon {
    margin-right: 8px;
  }

  & .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 48px;
  }

  & .tooltip {
    display: flex;
    align-items: center;
    gap: 7px;
  }

  & .main {
    margin-top: 43px;
    margin-bottom: auto;

    & .title {
      margin-bottom: 32px;
      font-size: 30px !important;

      @include for-desktop-down {
        margin-bottom: 24px;
        font-size: 24px !important;
      }
    }

    & .text {
      margin-bottom: 32px;
      display: block;
    }

    & .formItem {
      min-height: 72px;
    }

    & .sendButton {
      margin-top: 8px;
      width: 100%;
    }

    & .resetPasswordButton {
      margin-top: 16px;
      padding: 0;
    }

    & .bottomButtons {
      margin-top: 56px;
      display: flex;
      flex-direction: column;
      row-gap: 16px;
    }
  }
}
