.root {
  height: 100%;
  position: relative;

  & > svg {
    position: absolute;
    top: 8px;
    left: 16px;
  }

  & .labelWithLeftIcon {
    left: 48px;
  }
}

.input {
  position: relative;
  width: 100%;
  height: 40px;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  transition: all 0.1s;
  cursor: text;
  align-items: center;
  min-height: unset;
  box-shadow: none !important;
  background: transparent;
  border: none !important;
  border-radius: 7.5px;

  &,
  input {
    font-size: initial !important;
    background: transparent;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      transition: color 9999s ease-out, background-color 9999s ease-out;
      transition-delay: 9999s;
    }
  }

  & span[class*='suffix'] {
    z-index: 10;

    & > [class*='ant-input-data-count'] {
      font-weight: normal;
      font-size: 14px;
    }
  }

  &::before {
    height: 100%;
    width: 100%;
    transition: all 0.1s;
    position: absolute;
    content: '';
    inset: 0;
    visibility: visible;
    z-index: 0;
    border-width: 1px !important;
    border-style: solid;
    border-color: #5c5c6e !important;
    border-radius: 8px;
  }

  &:hover::before {
    transition: all 0.1s;
    border-color: #33bdcd !important;
    border-width: 2px !important;
  }

  &[class*='focused']::before {
    border-color: #0092a3 !important;
  }

  &[class*='status-error']::before {
    border-color: #ff4949 !important;
  }

  &[class*='disabled'] {
    cursor: not-allowed;

    &:hover::before {
      border-width: 1px !important;
      border-style: solid;
      border-color: #5c5c6e !important;
      border-radius: 8px;
    }
  }

  &:has(input:-webkit-autofill) ~ label {
    top: -10px;
    transform: translateY(0%);
    left: 12px !important;
    font-size: 12px !important;
    background: white !important;
    padding: 0 2px !important;
    z-index: 2;
  }
}

.smallInput {
  composes: input;
  height: 24px;

  &,
  input {
    font-size: 12px !important;
  }
}

.disabled {
  background-color: #f2f2f3;
}

input {
  font-weight: inherit;
}
