.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  padding: 16px 0;
  width: max-content;

  & .iconButton {
    width: 32px;
    height: 32px;
    border-radius: 100%;
    box-shadow: 0 1px 8px 0 rgb(0 0 0 / 15%);
    transition: .2s;

    &:active {
      box-shadow: 0 1px 8px 0 rgb(0 0 0 / 30%);
    }
  }
}