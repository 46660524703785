@import 'src/app/styles/mixins';

.container {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  flex: 1;
  justify-content: space-between;
  min-height: 0;

  @include for-phone-only {
    padding: 0 0 8px 0;
  }

  & .content {
    flex: 1;
    overflow: auto;

    @include for-phone-only {
      padding-right: 24px;
      margin-right: -24px;
    }
  }

  & .description {
    font-weight: 400 !important;
    margin-top: 24px;
    display: block;
    color: #444 !important;
  }
}

.footer {
  width: 100%;
  display: flex;
  gap: 16px;

  @include for-phone-only {
    flex-direction: column;
  }

  .footerButton {
    width: 50%;
    border-radius: 8px !important;

    @include for-phone-only {
      width: 100%;
    }
  }
}
.list {
  margin: 20px 0 40px -10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.listItem {
  font-weight: 400 !important;
  color: #444 !important;

}

.wrapper {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  height: 100%;

  & .icon {
    align-self: flex-end;
  }
}

.modal {
  top: 0;
  margin-bottom: 0;
  padding-bottom: 0;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  @include for-phone-only {
    display: block;
    width: 100vw;
    height: 100dvh;
    margin: 0;
  }

  :global(.ant-modal-content) {
    @include for-phone-only {
      height: 100dvh;
      width: 100vw;
      border-radius: unset;
      overflow: hidden;
    }
  }

  :global(.ant-modal-body) {
    @include for-phone-only {
      height: 100dvh;
    }
  }
}
