.root {
  height: 40px;
  width: 164px;
  color: #5c5c6e;
  display: flex;
  align-items: center;

  &:hover {
    color: #da1b5e;

    [class*='hiddenBox'] {
      display: flex;
      cursor: pointer;
      color: #da1b5e;
    }
  }
}

.hiddenBox {
  display: none;
}

.hiddenIcon {
  fill: #da1b5e;
}

.iconWrapper {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #cfcfd4;
  border-radius: 8px;
  margin-right: 12px;
}

.iconImage {
  max-height: 24px;
  max-width: 24px;
}

.text {
  font-weight: 400;
  width: 82px;
  color: inherit;
  margin-right: 12px;
  font-size: 12px;
}

@keyframes loadingCircle {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.fileUploader {
  width: 24px;
  height: 24px;

  animation: loadingCircle 1s infinite linear;
}
