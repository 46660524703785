.root {
  width: 100%;
  margin-bottom: 16px;
  box-shadow: 0 4px 20px rgb(0 0 0 / 10%);

  :global(.ant-card-body) {
    padding: 24px 24px 0;
  }
}

.titleWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
}

.switch {
  margin-bottom: 16px;
}

.formItem {
  min-height: auto;
}
