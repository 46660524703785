.root {
  width: 100%;
  margin-bottom: 16px;
  box-shadow: 0 4px 20px rgb(0 0 0 / 10%);

  :global(.ant-card-head) {
    padding: 24px 24px 0;
  }
  :global(.ant-card-body) {
    padding: 16px 24px;
  }
  :global(.ant-card-head-title) {
    overflow: visible;
  }
  :global(.ant-card-body) {
    padding: 0;
  }
}

.titleWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  margin-bottom: 32px;
}

.title {
  font-weight: 700;
  font-size: 20px;
}

.text {
  font-weight: 700;
  display: block;
  margin-bottom: 16px;
}

.radioGroup {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;

  :global(.ant-radio-button-wrapper) {
    width: 285px;
    border-radius: 8px;
    font-weight: normal;
    border-inline-start-width: 1px;

    &::before {
      display: none;
    }
  }

  :global(.ant-radio-button ~ span) {
    display: flex;
    align-items: center;
    gap: 8px;

    & > svg path {
      stroke: currentColor;
    }
  }
}
