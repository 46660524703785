.arrangePopupWrapper {
  :global(.AppFormSection:last-child .AppFormItem) {
    min-height: unset;
  }
}

.filterTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 13px;
}

.divider {
  margin: 14px 0 16px;
}

.datePickerFormItem {
  min-height: auto;

  :global(.react-datepicker-wrapper) {
    width: 100%;
  }

  :global(.react-datepicker__close-icon::after) {
    min-width: 12px;
    min-height: 12px;
    background-color: #9696a2;
  }

  :global(.ant-input-suffix) {
    display: none;
  }
}

.radioGroupWrapper {
  margin-bottom: 0;

  & .radioGroup {
    display: flex !important;
    flex-direction: column !important;
    height: auto !important;

    & > * {
      margin-bottom: 13px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}


