.root {
    :global(.ant-modal-close span) {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    :global(.ant-modal-content) {
        padding: 34px 24px 20px 24px;
    }

    :global(.ant-modal-footer) {
        margin-top: 8px;
    }

}

.footer {
    gap: 16px;
    display: flex;
    align-items: center;
    flex: 1;
}