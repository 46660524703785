.mediaWrapper {
  height: 112px;
  width: 209px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  z-index: 11;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  &:hover [class*='hiddenBox'] {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.7);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    cursor: default;
  }
}

.hiddenBox {
  display: none;
}

.button {
  display: flex;
  align-items: center;
}

.deleteButton {
  font-weight: 700px;
  color: currentColor;
}

.mediaWrapperLoading {
  composes: mediaWrapper;
  background-color: #f2f2f3;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes loadingCircle {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.fileUploader {
  width: 24px;
  height: 24px;

  animation: loadingCircle 1s infinite linear;
}
