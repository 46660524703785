@import '../../../../app/styles/mixins';

.section {
  min-height: 703px;
  justify-content: center;
  display: flex;
  padding-inline: 32px;
  column-gap: 104px;

  @include forSmallDesktop {
    column-gap: 64px;
  }

  @include forLaptops {
    flex-direction: column;
  }

  @include for-tablet-only {
    padding-inline: 24px;
  }

  & .mainContainer {
    display: flex;
    align-items: center;
  }


  & .headlineBlock {
    max-width: 560px;
    padding-top: 120px;

    @include forSmallDesktop {
      max-width: 504px;
    }

    @include forLaptops {
      padding-top: 40px;
      max-width: calc(100vw - 371px);;
    }

    @include for-tablet-only {
      padding-top: 40px;
      max-width: 90%;
    }

    & .title {
      margin-bottom: 24px;
      word-break: normal !important;
      overflow-wrap: normal;
      color: #fff !important;
      max-width: calc(100vw - 54px);

      @include forSmallDesktop {
        width: 14ch;
      }

      @include for-tablet-only {
        width: 100%;
        margin-bottom: 16px;
      }
    }

    & .subTitle {
      margin-top: 0;
      margin-bottom: 40px;

      @include for-tablet-only {
        margin-bottom: 24px;
      }
    }
  }
}