.wrapper {
  display: flex;
  align-items: center;
  gap: 12px;
  position: absolute;
  top: -40px;
  right: 0;
}
.btn {
  height: 24px !important;
  padding: 1.4px 7px !important;
  font-weight: 700;
  box-shadow: none;
}
.saveBtn {
  composes: btn;
  width: auto;
}

.deleteBtn {
  composes: btn;
  width: auto;
  border: 1px solid #191919;
  font-size: 12px !important;
}
