.root {
  position: relative;
  max-width: 936px;
}

.btn {
  height: 24px !important;
  padding: 1.4px 7px !important;
  font-weight: 700;
  box-shadow: none;

  position: absolute;
  top: -38px;
  right: 0;
}
