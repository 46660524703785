.cardBodyWrapper {
  padding: 24px 24px 0;
}

.text {
  font-weight: 700;
  display: block;
  margin-bottom: 16px;
}

.row {
  display: flex;
  gap: 16px;
}
