.container {
  display: flex;
  flex-wrap: wrap;
  row-gap: 8px;

  .blurContainer {
    margin-right: 8px;
    position: relative;

    width: calc(50% - 8px);

    &:nth-child(even) {
      margin-right: 0;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      backdrop-filter: blur(10px);
      z-index: 1;
      box-shadow: 0px 24px 30px 0px #0000000D, -1px 1px 1px -2px #FFFFFF1F inset, 0px 4px 4.1px 0px #00000017;
    }

    & .areaItem {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      padding: 16px;
      z-index: 1;

      & .title {
        text-align: center;
        margin-top: 8px;
        hyphens: auto;
      }

      & .subTitle {
        margin-top: 4px;
      }

      & .icon {
        width: 48px;
        height: 48px;
      }
    }
  }

  & .lastAreaItem {
    width: calc(100% + 8px);
  }
}