@import '../../../../app/styles/mixins';

.squareSectionContainer {
  position: relative;
  overflow: hidden;
  will-change: transform;

  & .progressBarContainer {
    @include for-tablet-only {
      left: 56%
    }
  }

  & .squareContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    will-change: transform;
    height: 380px;
    background: linear-gradient(0deg, rgb(255 255 255 / 85%) 0%, rgb(255 255 255 / 85%) 100%);

    @include for-desktop-up {
      height: 490px;
    }

    @include for-tablet-only {
      height: 248px;
    }

    & .container {
      @include for-tablet-only {
        margin: 37px 8px 25px;
      }

      & .innerSquare {
        display: flex;
        flex-wrap: wrap;
        row-gap: 12px;
        position: relative;
        width: 100%;
        will-change: transform;

        & .squareSection {
          display: flex;
          width: calc(50% - 6px);
          margin-right: 12px;
          padding: 16px;

          @include for-tablet-only {
            padding: 12px;
          }

          &:nth-child(even) {
            margin-right: 0;
          }

          & .categoriesContainer {
            display: flex;
            flex-grow: 1;
            column-gap: 12px;

            & .rectangleCard {
              display: flex;
              flex-direction: column;
              flex: 1 1;
              padding: 12px;
              align-items: center;
              box-shadow: 0px -1px 0.6px 0px #FFFFFF24 inset, 4px 7px 4px -1px #00000040 inset, 1px 0px 5.1px 0px #FFFFFF40 inset;

              @include for-tablet-only {
                padding: 12px 6px;
              }

              & .title {
                margin-top: 8px;
                hyphens: auto;

                @include for-tablet-only {
                  margin-top: 6px;
                }
              }

              & .coveredCategoriesAmount {
                margin-top: auto;
                font-weight: 700 !important;
                font-size: 16px !important;

                @include for-tablet-only {
                  font-size: 12px !important;
                }
              }

              & .icon {
                width: 40px;
                height: 40px;
              }
            }

            & .categoryCovered {
              box-shadow: -1px -1px 4px 0px #FFFFFF1A, 2px 4px 4px -1px #00000040;
            }

            & .categoryOuter {
              box-shadow: none;
            }
          }
        }

        & .outerSquare {
          box-shadow: none;
        }

        & .oddSquare {
          width: 100%;
          margin-right: 0;
        }
      }
    }
  }
}