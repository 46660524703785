@import '../../../../app/styles/mixins';

.wrapper {
  padding: 24px;
  background-color: #f5f5f6;
  border-radius: 4px;

  @include for-tablet-down {
    width: 100%;
    padding: 11px 16px;
  }

  @include for-phone-only {
    border-radius: 8px;
    background: #f7f7f8;
    padding: 16px;
  }

  .title {
    display: block;
    line-height: 22px;

    @include for-desktop-down {
      display: inline-block;
    }
  }

  .statusName {
    display: block;
    line-height: 1.3;
    margin: 0 0 16px;
    color: currentColor !important;

    @include for-desktop-down {
      display: inline-block;
    }

    @include for-phone-only {
      margin: 0 0 8px;
    }
  }

  .pointsSection {
    display: flex;
    align-items: baseline;
  }

  .currentPoints {
    display: flex;
    align-items: center;
    font-size: 48px;
    line-height: 65px;
    color: #444;
    font-weight: bold;

    @include for-tablet-down {
      color: currentColor;
      font-size: 14px;
      font-weight: 700;
      line-height: normal;
    }

    @include for-phone-only {
      font-size: 35px;
      font-weight: 700;
      line-height: 48px;
      color: #444;
    }
  }

  .points {
    color: #858899 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    @include for-tablet-down {
      font-size: 14px;
      font-weight: 700;
      line-height: normal;
    }

    @include for-phone-only {
      font-size: 16px;
    }
  }

  .description {
    display: flex;
    gap: 4px;
    align-items: center;
    margin-bottom: 28px;
    color: #444 !important;
    line-height: 22px;
    & > span {
      font-weight: 400;
      display: inline-flex;
      align-items: center;
    }

    & > b {
      display: inline-flex;
      align-items: center;
    }

    @include for-tablet-down {
      font-size: 14px;
      line-height: normal;
    }
  }

  .button {
    width: 256px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include for-tablet-down {
      width: 147px;
      height: 30px;
    }

    @include for-phone-only {
      width: 100%;
      height: 40px;
    }
  }

  .starIcon {
    height: 40px;
    width: 40px;

    @include for-tablet-down {
      width: 14px;
      height: 14px;
    }

    @include for-phone-only {
      width: 35px;
      height: 35px;
    }
  }
}

.divider {
  @include for-tablet-down {
    font-size: 14px;
  }
  @include for-phone-only {
    font-size: 32px;
  }
}

.badge {
  color: rgb(68 68 68 / 70%);
  font-size: 10px;
  font-weight: 600;
}

.mainTitle {
  margin-bottom: 0 !important;
}

.tabletWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  & > div {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 32px;

    & > p {
      margin-bottom: 0;
    }
  }

  .pointsSection {
    margin: 0;
  }

  .description {
    margin-bottom: 0;
  }

  .points {
    margin-bottom: 0;
    display: flex;
    align-items: center;
  }

  .currentPoints {
    margin-bottom: 0;
    color: #444;
  }

  .statusName {
    display: inline-block;
    margin-bottom: 0;
    line-height: 14px;
  }
}

.bold {
  font-weight: bold;
}
