@import '../../../../app/styles/mixins';

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 10px 12px;
  border-radius: 8px;
  color: #b3b3bb;
  position: relative;

  .coveredSublevel {
    background: currentColor;
    box-shadow: 1px 2px 4px 0 #0000004d inset;
  }

  @include for-tablet-down {
    min-width: 118px;
    width: 100%;
  }

  @include for-phone-only {
    border-radius: 8px;
    background: #f7f7f8;
  }

  .title {
    display: block;
    line-height: 1.5;
    margin: 0 0 24px;
    word-break: keep-all;
    color: currentColor !important;
    text-align: center;

    @include for-desktop-down {
      font-size: 14px !important;
      line-height: 19px;
    }
  }

  .icon {
    margin-bottom: 40px;

    @include for-desktop-down {
      width: 24px;
      height: 24px;
    }
  }

  .currentPoints {
    line-height: 27px;
    font-size: 20px;
    color: inherit;
  }

  .sublevels {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    width: 100%;
  }

  .sublevel {
    width: 100%;
    min-width: 20px;
    height: 12px;
    border-radius: 8px;
    box-shadow: 1px 2px 4px 0 #0000004d inset;
  }
}

.active {
  background-color: #fff;
  box-shadow: 0 1px 11px 0 #0003;
  color: #444;
}

.mobileWrapper {
  min-width: 100%;
  color: #b3b3bb;

  .title {
    display: block;
    margin-right: 8px;
    line-height: 16px;
    font-size: 12px;
    word-break: keep-all;
    color: currentColor !important;
  }

  .coveredSublevel {
    background: currentColor;
    box-shadow: 1px 2px 4px 0 #0000004d inset;
  }

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }

  .header {
    display: flex;
    margin-bottom: 10px;
  }

  .icon {
    height: 16px;
    width: 16px;
  }

  .mobilePoints {
    margin-left: auto;
    line-height: 16px;
    font-size: 12px;
    word-break: keep-all;
    color: currentColor;
  }

  .sublevels {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
  }

  .sublevel {
    width: 100%;
    min-width: 25px;
    height: 12px;
    border-radius: 8px;
    box-shadow: 1px 2px 4px 0 #0000004d inset;
  }

  .sublevelsWrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    border-radius: 8px;
    background: rgb(68 68 68 / 5%);
    margin-bottom: 9px;
    padding: 8px 10px;
  }

  .points {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;

    @include for-phone-only {
      margin-left: auto;
      margin-bottom: 0;
    }
  }
}

.desktopPoints {
  position: absolute;
  bottom: -25px;
  left: -24px;
  display: flex;
  align-items: center;
}
