.root {
  width: 100%;
  margin-bottom: 16px;
  box-shadow: 0 4px 20px rgb(0 0 0 / 10%);

  :global(.ant-card-body) {
    padding: 24px;
  }

  :global(.ant-segmented .ant-segmented-item-selected) {
    background-color: #00765f;
  }
  :global(.ant-segmented .ant-segmented-thumb) {
    background-color: #00765f;
  }

  .header {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }

  .radioGroup {
    min-height: auto;
  }

  .text {
    font-size: 12px;
    font-weight: 700;
    display: block;
    margin-bottom: 16px;
  }
}
