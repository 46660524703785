@import 'src/app/styles/mixins';

.wrapper {
  height: 300px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;

  @include for-desktop-down {
    height: 236px;
  }

  @include for-phone-only {
    display: block;
    padding: 24px 0 0 0;
    height: 160px;
  }

  .headlineBlock {
    margin: 0 0 0 92px;

    @include for-phone-only {
      margin: 0 0 0 16px;
    }
  }

  .title {
    margin: 0!important;

    @include for-desktop-down {
      font-size: 16px!important;
      line-height: normal;
    }

    @include for-phone-only {
      font-size: 14px!important;
      line-height: normal;
    }
  }

  .heading {
    margin-bottom: 6px;

    @include for-desktop-down {
      font-size: 32px!important;
      line-height: 38px;
    }

    @include for-phone-only {
      font-size: 24px!important;
      line-height: normal;
    }
  }

  .white {
    color: #fff!important;
  }
}
