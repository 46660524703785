.root {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.title {
  font-weight: bold;
}

.fontsWrapper {
  margin-top: 24px;
  display: flex;
  flex-direction: column;

  .fontLabel {
    margin-bottom: 16px;
  }

  .fontItem {
    display: flex;
    gap: 16px;
  }
}

.hr {
  width: 933px;
  margin-left: -24px;
}

.fontUploadButton {
  margin-top: 16px;
  display: inline-flex;
  align-items: center;
  gap: 10px;
}
