.card {
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    margin-top: 24px;
    font-size: 30px!important;
    line-height: 38px;
    letter-spacing: -0.6px;
    text-align: center!important;
  }

  .text {
    text-align: center;
    white-space: break-spaces;
  }

  .button {
    margin: 32px 0 0;
    align-self: stretch;

    &_text {
      color: #fff!important;
    }
  }
}
