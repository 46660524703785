.header {
  & .title {
    color: #191919;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    margin-bottom: 32px;
  }
}

.row {
  display: flex;
  justify-content: space-between;
  column-gap: 16px;
}
