.sidebar{
  min-width: 240px;
  background-color: #404055;
  position: sticky;
  height: calc(100dvh - 75px);
  overflow-y: auto;
  top: 0;
  z-index: 100;


  &_menu{
    height: 100%;
    border-right: 0;
    background: transparent;
    color:white;

    &_active{
      font-weight: bold;
    }
  }
}

