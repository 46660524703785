.font_color {
  &_wrap {
    display: flex;
    align-items: center;
  }

  &_square {
    height: 26px;
    width: 26px;
    border: 1px solid #cfcfd4;
    border-radius: 5px;
    margin-right: 10px;

    &.small {
      height: 16px;
      width: 16px;
      margin-right: 8px;
    }

    &.large {
      height: 26px;
      width: 42px;
      margin-right: 8px;
    }
  }
}
