.wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
  cursor: pointer;
  &:last-child {
    margin-bottom: 0;
  }
}

.picture {
  width: 68px;
  height: 40px;
  margin-right: 16px;
  border-radius: 4px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.text {
  width: calc(100% - 86px);
}
