.root {
  width: 100%;
  margin-bottom: 16px;
  box-shadow: 0 4px 20px rgb(0 0 0 / 10%);
  position: relative;
}

.sectionTitle {
  display: block;
  margin-bottom: 24px;
  font-weight: 700;
  font-size: 20px;
}

.section {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 16px;

  :global(.ant-form-item) {
    margin-bottom: 0;
    min-height: auto;
  }

  & > div {
    &:first-of-type {
      grid-area: 1 / 1 / 2 / 3;

      :global(.ant-form-item) {
        min-height: 58px;
      }
    }
    &:nth-child(2) {
      grid-area: 2 / 1 / 3 / 2;
    }
    &:nth-child(3) {
      grid-area: 2 / 2 / 3 / 3;
    }
  }
}

.formItemTitle {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 16px;
}

.trashButton {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 16px;
  right: 16px;
}
