@import '../../../../app/styles/mixins';

.wrapper {
  padding: 40px 24px;

  .headerTitle {
    margin-bottom: 24px;

    @include for-tablet-only {
      font-size: 20px !important;
      font-weight: 700 !important;
      margin-bottom: 14px;
    }

    @include for-phone-only {
      font-size: 16px !important;
      font-weight: 700 !important;
      margin-bottom: 16px;
    }
  }

  & .pointsContainer {
    display: flex;
    justify-content: center;
    height: max-content;
    column-gap: 2px;

    & .icon {
      width: 16px;
      height: 16px;

      @include for-tablet-only {
        width: 14px;
        height: 14px;
      }

      @include for-phone-only {
        width: 14px;
        height: 14px;
      }
    }
  }

  @include for-phone-only {
    padding: 24px 16px;
  }

  @include for-desktop-up {
    padding: 60px 88px;
  }
}

.cardWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 16px;
}

.card {
  justify-content: space-between;
  display: flex;
  gap: 50px;
  width: 100%;
  border-bottom: 1px solid #cfcfd4;
  padding: 16px 0;

  @include for-phone-only {
    padding: 0;
    border: none;
  }
}

.pointsTitle {
  display: flex;
  align-items: center;
  gap: 4px;
  white-space: nowrap;

  @include for-tablet-only {
    font-size: 14px !important;
  }

  @include for-phone-only {
    font-size: 14px !important;
  }
}

.summaryCard {
  justify-content: space-between;
  display: flex;
  gap: 50px;
  width: 100%;
  padding-bottom: 16px;

  & .summaryIcon {
    width: 20px;
    height: 20px;

    @include for-tablet-only {
      width: 16px;
      height: 16px;
    }

    @include for-phone-only {
      width: 14px;
      height: 14px;
    }
  }
}

.textBlock {
  display: flex;
  gap: 48px;

  @include for-phone-only {
    gap: 16px;
  }
}

.image {
  height: 95px;
  width: 143px;

  @include for-phone-only {
    width: 32px;
    height: 32px;
  }
}

.button {
  padding: 0;
  font-weight: 700;
  font-size: 12px;
  height: 16px;
}

.title {
  @include for-desktop-down {
    font-size: 16px !important;
  }

  @include for-phone-only {
    font-size: 14px !important;
    font-weight: 400 !important;
    margin-bottom: 1px !important;
  }
}

.pointHeadline {
  display: flex;
  justify-content: center;
  height: max-content;
  column-gap: 2px;

  @include for-tablet-only {
    font-size: 16px !important;
  }

  @include for-phone-only {
    font-size: 14px !important;
  }
}
