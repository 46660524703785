@import '../../../../app/styles/mixins';

.container {
  width: 880px;
  max-height: 703px;
  padding: 32px 0;
  display: flex;
  flex-wrap: wrap;
  row-gap: 12px;

  @include forSmallDesktop {
    width: 808px;
  }

  @include forLaptops {
    width: 100%;
  }

  @include for-tablet-only {
    padding-top: 48px;
  }

  & .areaContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 205px;
    padding: 12px;
    width: 434px;
    box-shadow: 0px 24px 30px 0px #0000000D, -1px 1px 1px -2px #FFFFFF1F inset, 0px 4px 4.1px 0px #00000017;
    z-index: 1;

    @include forSmallDesktop {
      width: 398px;
    }

    @include forLaptops {
      width: 100%;
    }

    & .categoriesContainer {
      flex-grow: 1;
      display: flex;
      margin-top: 16px;
      column-gap: 8px;
      cursor: pointer;

      & .categoryItem {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 12px 6px;
        flex: 1 1;
        box-shadow: 0px -1px 0.6px 0px #FFFFFF24 inset, 4px 7px 4px -1px #00000040 inset, 1px 0px 5.1px 0px #FFFFFF40 inset;

        & .icon {
          width: 40px;
          height: 40px;
        }

        & .title {
          text-align: center;
          margin-top: 8px;
          hyphens: auto;
        }

        & .coveredCategoriesAmount {
          margin-top: auto;
          font-weight: 700 !important;
        }
      }

      & .isCovered {
        box-shadow: -1px -1px 1px 0px #FFFFFF17, 2px 4px 4px -1px #00000040;
      }

      & .addedToWishlist {
        box-shadow: none;
      }
    }
  }

  & .oddArea {
    width: 880px;

    @include forSmallDesktop {
      width: 808px;
    }

    @include forLaptops {
      width: 100%;
    }
  }
}

.blurContainer {
  margin-right: 12px;
  position: relative;

  &:nth-child(even) {
    margin-right: 0;
  }

  @include forLaptops {
    width: calc(50% - 6px)
  }
}

.blurLastOddArea {
  @include forSmallDesktop {
    margin-right: 0;
  }

  @include forLaptops {
    width: 100%;
    margin-right: 0;
  }
}

.blurContainer::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(10px);
  z-index: 1;
  box-shadow: 0px 24px 30px 0px #0000000D, -1px 1px 1px -2px #FFFFFF1F inset, 0px 4px 4.1px 0px #00000017;
}
