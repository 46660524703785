.root {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  background-color: #858899;
  border-radius: 8px;
  padding: 5px 8px;
}

.green {
  background-color: #008f73;
}

.orange {
  background-color: #fc9155;
}

.gray {
  background-color: #858899;
}

.red {
  background-color: #da1b5e;
}

.text {
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  color: #fff;
}
