.root {
  position: relative;

  :global(.ant-modal-body) {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100vh - 70px);
  }

  :global(.ant-modal-content) {
    padding: 0 !important;
  }

  .iconButton {
    position: absolute;
    right: 16px;
    top: 16px;
    width: 24px;
    height: 24px;

    & > svg {
      width: 14px;
      height: 14px;
      color: #5C5C6E
    }
  }
}

:global(.ant-modal-mask) {
  background: rgba(0, 0, 0, 0.5) !important;
}