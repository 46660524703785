@import '../../../../app/styles/mixins';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 12px 88px;
  gap: 16px;
  background-color: #fff;
}

@include for-desktop-down {
  .wrapper {
    padding: 24px;
    display: grid;
    grid-template-columns: minmax(0, 300px) minmax(0, 1fr);
    grid-template-rows: 80px 1fr;
    grid-gap: 24px 16px;

    & > div:first-child {
      grid-area: 1 / 1 / 2 / 3;
    }
  }

  .currentStatusCard {
    grid-area: 2 / 1 / 3 / 2;
    align-self: flex-start;
  }

  .productsCard {
    grid-area: 2 / 2 / 3 / 3;
    align-self: flex-start;
    height: 100%;
  }

  .products {
    height: 100%;
    display: flex;
    flex-direction: column;

    & > div {
      width: 100%;
      background-color: #f7f7f8;
      border-radius: 8px;
      margin-bottom: 0;
    }
  }
}

@include for-phone-only {
  .wrapper {
    padding: 16px;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-gap: 16px 0;

    & > div:first-child {
      grid-area: 3 / 1 / 4 / 2;
      flex-direction: column-reverse;
    }
  }

  .currentStatusCard {
    grid-area: 2 / 1 / 3 / 2;
  }

  .productsCard {
    grid-area: 1 / 1 / 2 / 2;
  }
}
