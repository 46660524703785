.root {
  width: 100%;

  :global(.ant-tabs-nav::before) {
    display: none;
  }

  :global(.ant-tabs-tab) {
    margin-right: 8px;
    padding-top: 0;
    padding-bottom: 6px;
  }

  :global(.ant-tabs-nav) {
    margin-bottom: 10px;
  }
}
