@import '../../../../app/styles/mixins';

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 60px 93px 0;
  overflow: hidden;

  & .wrapper {
    padding: 60px 93px 0;
    margin: -60px -93px 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: linear-gradient(76deg, rgb(0 0 0 / 51%) 5.37%, rgb(0 0 0 / 46%) 27.78%, rgb(0 0 0 / 0%) 90.96%);
  }

  @include for-desktop-down {
    display: none;
  }

  & .header {
    width: 100%;
    margin-bottom: 36px;

    & .title {
      margin-bottom: 16px;
      word-break: normal!important;
      overflow-wrap: normal;
      color: #fff!important;
      width: auto;

      @container (max-width: 1400px) {
        margin-bottom: 16px;
        white-space: break-spaces;
        color: #fff !important;
        width: 24ch!important;
      }

      @include specific-small-dDesktop {
        width: 18ch !important;
      }
    }

    & .subTitle {
      margin: 0;
      width: 70%;
      color: #fff !important;
    }
  }

  & .houseModelWrapper {
    display: flex;
    justify-content: center;
  }
}
