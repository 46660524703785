.subLevelsContainer {
  display: flex;
  flex-direction: column;

  & .subLevelItem {
    display: flex;
    align-items: flex-start;
    height: 36px;
  }

  & .points {
    justify-content: flex-end;
  }
}

.arrowContainer {
  display: flex;
  justify-content: flex-end;
}