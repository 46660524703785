.mobilePentagon {
  width: 100%;
  height: 178px;
  position: relative;
  display: flex;
  clip-path: polygon(50% 0%, 130% 20%, 100% 100%, 0% 100%, -30% 50%);
  gap: 7px;
}

.mobileRectangle {
  width: 100%;
  height: 160px;
  top: 100%;
  left: 0;
  padding-top: 7px;
  display: flex;
  gap: 7px;
}

.mobilePentagonSector {
  width: 100%;
  min-height: 178px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  padding: 10px;
  padding-bottom: 20px;
  position: relative;
}
.mobilePentagon .mobilePentagonSector:nth-child(1) {
  clip-path: polygon(100% 0%, 100% 100%, 0% 100%, 0% 38%);
}

.mobilePentagon .mobilePentagonSector:nth-child(2) {
  clip-path: polygon(0% 0%, 100% 37%, 100% 100%, 0% 100%);
}

.mobileRectangleSector {
  width: 50%;
  min-height: 160px;
  float: left;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;
  position: relative;
  .icon {
    top: 15%;
    left: 50%;
    transform: translate(-50%);
  }
}

.mobileHouse {
  width: 100%;
  height: 100%;
  height: 346px;
}

.icon {
  width: 53px;
  height: 53px;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%);
}

.productCard {
  width: 100%;
  background-color: #fff;
  padding: 16px;
}

.productCardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(68, 68, 68, 0.2);
  margin-bottom: 16px;
  padding-bottom: 13px;
  > p {
    margin: 0;
  }
}

.productCardItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > div {
    display: flex;
    align-items: center;
    gap: 12px;
    & > p {
      margin: 0;
    }
  }
  & > p {
    margin: 0;
  }
}

.itemIcon {
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  > img {
    width: 22px;
    height: 22px;
  }
}

.productCardList {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
  margin: 0 !important;
  margin-top: 12px !important;
  color: #fff !important;
  font-size: 14px !important;
  text-align: center;
}

