.wrapper {
  padding: 16px 16px 20px;
  position: relative;

  & .subTitle {
    max-width: 90%;
  }

  & .tilesContainer {
    margin-top: 21px;
  }

  & .headlineBlock {
    position: relative;
    z-index: 10;
  }

  & .overlay {
    width: 100%;
    height: 181px;
    position: absolute;
    top: -16px;
    left: 0;
    background: linear-gradient(180deg, rgba(234, 238, 248, 0.9) 7.18%, rgba(234, 238, 248, 0.826087) 28.85%, rgba(234, 238, 248, 0.716184) 45.28%, rgba(234, 238, 248, 0.6) 58.06%, rgba(234, 238, 248, 0.2) 81.8%, rgba(234, 238, 248, 0) 93.85%);
  }
}