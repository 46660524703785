.root {
  margin-top: 15px;
}

.buttons {
  display: flex;
  column-gap: 16px;
}

.buttonWrapper {
  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    height: 24px;
    padding: 0 8px;
    color: #191919;
    line-height: 12px;
    font-weight: bold;
    font-size: 12px;
    border: 1px solid #191919;
    background: none;

    .addIcon {
      fill: #191919;
    }

    .uploadIcon {
      fill: #191919;
    }
  }

  :hover .addIcon,
  :hover .uploadIcon {
    fill: #da1b5e;
    transition: 0.2s;
  }
}

.heading {
  display: flex;
  gap: 16px;
}
