@import '../../../../app/styles/mixins';

.container {
  padding: 32px 40px;
  width: 528px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 20px 0 rgb(0 0 0 / 10%);
  position: absolute;
  inset: 24px 0 0 0;
  bottom: unset;
  margin: 0 auto;

  @include for-desktop-down {
    width: 375px;
    padding: 24px;
    position: relative;
    height: 600px;
    inset: 0;
  }

  @include for-phone-only {
    width: 100%;
    height: 100dvh;
    border-radius: 0;
    inset: 0;
  }

  & .logo {
    max-width: 240px;
    height: 49px;
    object-fit: cover;

    @include for-desktop-down {
      max-width: 152px;
      height: 32px;
    }

    @include for-phone-only {
      max-width: 142px;
      height: 32px;
    }
  }

  & .icon {
    margin-right: 8px;
  }

  & .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 48px;
  }

  & .tooltip {
    display: flex;
    align-items: center;
    gap: 7px;
  }

  & .main {
    margin-top: 48px;
    margin-bottom: auto;

    @include for-desktop-down {
      margin-top: 32px;
    }

    & .title {
      margin-bottom: 40px;
      font-size: 30px !important;

      @include for-desktop-down {
        margin-bottom: 32px;
        font-size: 24px !important;
      }
    }

    & .text {
      margin-bottom: 32px;
      display: block;
    }

    & .formItem {
      min-height: 70px;
      margin-bottom: 16px;

      &:nth-child(2) {
        margin: 0;
      }

      label {
        font-size: 14px;
        display: flex;
        align-items: start;

        span {
          padding-inline-end: 0;
          padding-inline-start: 0;
          align-self: flex-start;
        }

        :global(.ant-checkbox-inner) {
          margin-right: 14px;
        }
      }

      &:global(.ant-form-item-has-error) {
        & span, a {
          color: #e64242!important;
          border-color: #e64242 !important;
        }
      }

      @include for-desktop-down {
        &:first-child {
          margin-bottom: 16px;
        }
      }
    }

    & .btn {
      margin: 4px 0 16px;
      width: 100%;

      @include for-desktop-down {
        margin-top: 12px;
      }
    }
  }
}
