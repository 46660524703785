.textArea {
  margin-bottom: 28px;
  height: 80px;

  textarea {
    resize: none;
    background-color: transparent;
  }
}

.inputUrl {
  padding-left: 48px !important;
}
