.searchField {
  margin: 0;

  :global(.ant-form-item-explain-error) {
    margin-bottom: 0 !important;
  }
}

.formSection {
  margin: 0;
}

.formItem {
  min-height: unset;
}

.formItemExtended {
  min-height: 43px;
}

.root {
  display: flex;
  flex-direction: column;
  margin-top: 14px;
}

.divider {
  margin: 8px 0;
}

.noResults {
  display: flex;
  justify-content: center;
  padding: 6px 0 10px;
}

.checkbox {
  overflow: hidden;
  label {
    margin-bottom: 13px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  :global(.ant-checkbox + span) {
    width: 180px;
  }
}

.checkboxGroupRoot {
  & > * {
    margin-inline-start: 0;
  }
}
