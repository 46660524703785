.headlineBlock {
  margin-bottom: 32px;

  & .title {
    max-width: 14ch;
    margin-bottom: 8px;
  }

  & .subTitle {
    margin-bottom: 12px;
  }
}