.title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 12px;
}

.content{
  margin-top: 24px;
}

.footer {
  display: flex;
  gap: 16px;
  flex: 1;
  align-items: center;

  &_button {
    width: 100%;
    font-weight: bold;
  }
}