@import '../../../../app/styles/mixins';

.squareSectionContainer {
  position: relative;
  z-index: 100;
  overflow: hidden;
  will-change: transform;
}

.squareContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include for-desktop-up {
    height: 490px;
  }
  will-change: transform;

  height: 380px;
  background: linear-gradient(0deg, rgb(255 255 255 / 85%) 0%, rgb(255 255 255 / 85%) 100%);
}

.bigSquare {
  display: grid;
  flex-direction: row;
  min-width: 1000px;
  min-height: 1000px;
  overflow: hidden;
  transition: transform 0.5s ease-in-out;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(2, 1fr);
  position: relative;
  z-index: 1;
  will-change: transform;
}

.subSquare {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  background-color: #ddd;
  transition: background-color 0.3s ease-in-out;
  will-change: transform;
}

.subSquare.active {
  background-color: #3498db;
  color: #fff;
}

.сontainer {
  margin: 68px 8px 88px;

  @include for-desktop-up {
    width: 896px;
  }

  width: 600px;
}

.pentagon {
  width: 100%;
  will-change: transform;

  @include for-desktop-up {
    height: 310px;
  }
  height: 260px;
  position: relative;
  display: flex;
  clip-path: polygon(50% 0%, 100% 50%, 100% 100%, 0% 100%, 0% 50%);
  background: linear-gradient(0deg, rgb(255 255 255 / 90%) 0%, rgb(255 255 255 / 90%) 100%);
}

.trapezoidCard {
  padding: 4px;

  flex: 1;
  box-shadow:
    0 5px 30px 0 rgb(0 0 0 / 5%),
    1px 4px 5px 0 rgb(0 0 0 / 30%);
}

.pentagon .pentagonSector {
  will-change: transform;

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pentagon .pentagonSector div {
  will-change: transform;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 90%;
  gap: 8px;

  > .trapezoidCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%);
      width: 40px;
      height: 40px;
    }

    .title {
      padding: 0 10px;
    }
  }
}

.pentagon .pentagonSector:nth-child(1) div {
  .trapezoidCard:nth-child(1) {
    .icon {
      top: 60%;
    }
  }

  .trapezoidCard:nth-child(2) {
    .icon {
      top: 45%;
    }
  }

  .trapezoidCard:nth-child(3) {
    .icon {
      top: 30%;
    }
  }
}

.pentagon .pentagonSector:nth-child(2) div {
  .trapezoidCard:nth-child(1) {
    .icon {
      top: 30%;
    }
  }

  .trapezoidCard:nth-child(2) {
    .icon {
      top: 55%;
    }
  }

  .trapezoidCard:nth-child(3) {
    .icon {
      top: 60%;
    }
  }
}

.pentagon .pentagonFourSector.pentagonSector:nth-child(1) div {
  .trapezoidCard:nth-child(1) {
    .icon {
      top: 60%;
    }
  }

  .trapezoidCard:nth-child(2) {
    .icon {
      top: 50%;
    }
  }

  .trapezoidCard:nth-child(3) {
    .icon {
      top: 35%;
    }
  }
  .trapezoidCard:nth-child(4) {
    .icon {
      top: 25%;
    }
  }
}

.pentagon .pentagonFourSector.pentagonSector:nth-child(2) div {
  .trapezoidCard:nth-child(1) {
    .icon {
      top: 25%;
    }
  }

  .trapezoidCard:nth-child(2) {
    .icon {
      top: 35%;
    }
  }

  .trapezoidCard:nth-child(3) {
    .icon {
      top: 50%;
    }
  }
  .trapezoidCard:nth-child(4) {
    .icon {
      top: 60%;
    }
  }
}

.pentagon .pentagonSector:nth-child(1) {
  clip-path: polygon(98% 8%, 98% 100%, 4.75% 100%, 4.75% 55%);
  position: relative;

  @include for-desktop-down {
    clip-path: polygon(97% 8%, 97% 100%, 6.75% 100%, 6.75% 54%);
  }
}

.pentagon .pentagonSector:nth-child(2) {
  clip-path: polygon(2% 8%, 95.25% 54%, 95.25% 100%, 2% 100%);
  position: relative;
  @include for-desktop-down {
    clip-path: polygon(3% 8%, 93.25% 54%, 93.25% 100%, 3% 100%);
  }
}

.pentagon .pentagonSector:nth-child(1) > div {
  width: 100%;
  height: 100%;
  clip-path: polygon(96% 12%, 96% 100%, 0% 100%, 0% 60%);
  display: flex;
  align-items: center;
  justify-content: space-around;
  @include for-desktop-up {
    padding-left: 32px;
    padding-right: 20px;
  }
  padding-left: 25px;
  padding-right: 15px;
}

.pentagon .pentagonSector:nth-child(2) > div {
  width: 100%;
  height: 100%;
  clip-path: polygon(4% 13%, 100% 60%, 100% 100%, 4% 100%);
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-left: 15px;
  padding-right: 25px;

  @include for-desktop-up {
    padding-left: 20px;
    padding-right: 32px;
  }
}

.rectangle {
  width: 100%;
  @include for-tablet-landscape-up {
    height: 260px;
  }
  height: 240px;
  top: 100%;
  left: 0;
  padding-top: 13px;
  display: flex;
  background: linear-gradient(0deg, rgb(255 255 255 / 90%) 0%, rgb(255 255 255 / 90%) 100%);
}

.rectangle .rectangleSector {
  width: 50%;
  height: 100%;
  background: linear-gradient(0deg, rgb(255 255 255 / 20%) 0%, rgb(255 255 255 / 20%) 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:nth-child(1) {
    clip-path: polygon(4.75% 0%, 98% 0%, 98% 93%, 4.75% 93%);

    @include for-desktop-down {
      clip-path: polygon(6.75% 0%, 97% 0%, 97% 93%, 6.75% 93%);
    }

    & > div {
      @include for-desktop-up {
        padding-left: 32px;
        padding-right: 20px;
      }
      padding-left: 25px;
      padding-right: 15px;
    }
  }

  &:nth-child(2) {
    clip-path: polygon(2.1% 0%, 95.25% 0%, 95.25% 93%, 2.1% 93%);

    @include for-desktop-down {
      clip-path: polygon(3% 0%, 93.25% 0%, 93.25% 93%, 3% 93%);
    }

    & > div {
      @include for-desktop-up {
        padding-left: 20px;
        padding-right: 32px;
      }
      padding-left: 15px;
      padding-right: 25px;
    }
  }
}

.cardsWrapper {
  width: 100%;
  height: 180px;
  gap: 8px;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.card {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 4px;
  box-shadow:
    0 5px 30px 0 rgb(0 0 0 / 5%),
    1px 4px 5px 0 rgb(0 0 0 / 30%);

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    height: 100%;

    .icon {
      margin-top: 50px;
      margin-bottom: auto;
      width: 40px;
      height: 40px;
    }

    .subText {
      align-self: flex-end;
    }
  }
}

.title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 3;
  text-align: center;
  hyphens: auto;
}

.squareContainer,
.pentagon,
.rectangleSector,
.rectangle {
  will-change: transform;
}
