.closeButton {
  position: absolute;
  top: -48px;
  right: 0;
  width: 40px !important;
  height: 40px !important;
  border-radius: 100%;
  background-color: #fff !important;
}

.container {
  padding: 24px 20px;
  background-color: #fff;

  & .imageContainer {
    margin-bottom: 12px;

    & .image {
      width: 100%;
      height: 153px;
      object-fit: cover;
    }
  }

  & .description {
    line-height: 20px;
    margin-bottom: 0;
  }
}
