@import '../../../../app/styles/mixins';

.tabs {
  :global(.ant-tabs-tab.ant-tabs-tab-active) {
    border-color: var(--primary-color) !important;
    border-bottom: 3px solid var(--primary-color) !important;
    z-index: 2;
  }

  :global(.ant-tabs-ink-bar) {
    transition: none !important;
    display: none;
  }

  :global(.ant-tabs-tab) {
    align-items: center;
    justify-content: center;
    display: flex;
    border-bottom: 3px solid transparent;

    @include for-tablet-landscape-up {
      padding: 10px 24px;
    }

    padding: 10px 4px;
  }

  :global(.ant-tabs-tab-btn) {
    color: #444;
    opacity: 0.75;
  }

  :global(.ant-tabs-nav) {
    :global(.ant-tabs-nav-wrap::before) {
      box-shadow: none !important;
    }

    :global(.ant-tabs-nav-wrap::after) {
      box-shadow: none !important;
    }

    :global(.ant-tabs-nav-list) {
      justify-content: space-between;

      @include for-desktop-up {
        width: 100%;
      }
    }
  }

  :global(.ant-tabs-nav) {
    margin-bottom: 0;
    margin-top: 10px;
  }

  :global(.ant-tabs-tab) {
    padding-top: 0;
    padding-bottom: 8px;
  }
}

.innerTab {
  display: flex;
  align-items: center;
  gap: 11px;

  > p {
    margin-bottom: 0;
    font-weight: 700;
    color: #444;
  }

  > button {
    border-radius: 0;
    padding: 5px 8px;
    color: #858899;
    background: #f7f7f8;
  }
}

.categorieCard {
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 15%);
  min-height: 50px;
  width: 400px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 10px 16px;
  cursor: pointer;
}

.categorieCardSuccessStatus {
  padding: 5px 12px 5px 6px;
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

:global(.ant-tabs-nav-more) {
  display: none;
}
