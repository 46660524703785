.root {
  margin-left: 170px;
  display: flex;
  align-items: start;
  justify-content: space-between;
  max-width: 936px;
  margin-top: 5px;
}

.btn {
  margin-top: -15px;
  font-weight: bold;
  height: 24px;
  padding: 0 16px;
}
