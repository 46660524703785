@import '../../../styles/mixins';

.wrapper {
  display: flex;
  align-items: center;
  height: 60px;
  padding: 0 88px;
  flex-shrink: 0;

  @include for-desktop-down {
    height: 133px;
    padding-bottom: 73px;
    padding-inline: 24px;
  }

  @include for-phone-only {
    height: auto;
    padding: 24px 0;
    padding-bottom: 80px;
    justify-content: center;
  }
}

.buttonLinks {
  height: 100%;
  display: flex;
  align-items: center;
  gap: 40px;
  line-height: initial;

  @include for-tablet-only {
    gap: 15px;
  }

  @include for-phone-only {
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0 32px;
    gap: 20px;
  }
}

.text {
  color: white !important;
  white-space: nowrap;
  text-align: center;

  @include for-phone-only {
    font-size: 14px !important;
    font-weight: 400 !important;
  }
}

.withShadow {
  box-shadow: 0 4px 20px rgb(0 0 0 / 10%);
}

.withoutFixedBar {
  margin-bottom: 0;
}
