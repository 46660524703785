.header {
  display: flex;
  align-items: center;
  min-width: 1300px;
  background: linear-gradient(180deg, rgb(255 255 255 / 4%) 0%, rgb(255 255 255 / 0%) 41.2%),
    linear-gradient(287.86deg, #282828 0%, #404055 48.28%, #141414 98.62%) !important;
  height: 75px;
  padding-inline: 24px;

  & .profile {
    display: flex;
    column-gap: 16px;
  }

  &_wrap {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

  &_logo_wrap {
    display: flex;
    align-items: center;
  }

  &_logo_image {
    margin-right: 16px;
    width: 33px;
    height: 35px;
  }

  &_text {
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;

    &::first-letter {
      text-transform: uppercase;
    }

    &__thin {
      margin-left: 10px;
      font-weight: 400;
      max-width: 520px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    &__title {
      color: #cfcfd4;
    }
  }

  &_decor {
    margin: 0 16px;
  }

  &_previewBtn {
    display: flex;
    align-items: center;
    align-self: center;
    width: max-content;
    height: 24px !important;
    margin: 0 40px 0 auto;
    padding: 0 7px;
    background-color: transparent;
    color: #fff;
    transition: none;

    :global(.ant-btn-icon) {
      margin-right: 8px;
    }

    span {
      font-size: 12px;
      align-self: center;
      line-height: 16px;
    }

    &:hover {
      path {
        fill: currentColor;
      }
    }
  }
}
