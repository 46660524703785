.form {
  width: 936px;
  position: relative;

  & .titleContainer {
    margin-bottom: 32px;
  }

  & .title {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 16px;
  }

  & .sublevelCard {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }

  & .sublevelCardHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 34px;

    & svg {
      fill: #5C5C6E;

      &:hover {
        fill: red;
        transition: 0.2s;
      }
    }
  }

  & .sublevelMiddleRow {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    column-gap: 16px;

    & .sublevelTitle {
      width: 730px;
    }

    & .sublevelPoints {
      width: 142px;
    }
  }

  & .subTitle {
    font-size: 12px;
  }

  .hasError {
    & :global(.ant-form-item-explain > *) {
      margin: 4px 0 0;
      font-size: 12px;
      color: #E64242;
      letter-spacing: -1.5%;
      text-align: right;
    }
  }

  & .addSublevelButton {
    display: flex;
    column-gap: 8px;
    margin-top: 24px;
    align-items: center;
    width: max-content;

    & .buttonTitle {
      color: #DA1B5E;
      font-size: 16px;
      font-weight: 700;
    }
  }

  & .addIcon {
    fill: #DA1B5E;
  }
}
