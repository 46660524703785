.wrapper {
  padding: 48px 21px;
  width: 448px;
  height: 435px;
  box-shadow: 0 4px 20px rgb(0 0 0 / 10%);

  & .title {
    text-transform: uppercase;
    margin-bottom: 56px;

    & .regularTitle {
      font-weight: normal;
    }
  }
}

