@import '../../../../app/styles/mixins';

.container {
  padding-right: 4px;
  width: 528px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 8px;
  align-self: center;
  justify-self: center;
  box-shadow: 0 4px 20px 0 rgb(0 0 0 / 10%);
  position: absolute;
  inset: 24px 0;
  margin: 0 auto;
  height: calc(100% - 48px);

  @include for-desktop-down {
    width: 375px;
  }

  @include for-phone-only {
    width: 100%;
    inset: 0;
    border-radius: 0;
    height: 100%;
  }

  & .header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 32px 40px;
    border-radius: 8px 8px 0 0;
    border-bottom: 1px solid rgb(102 102 102 / 40%);
    background: #fff;
    z-index: 100;

    @include for-desktop-down {
      padding: 32px 24px;
    }

    @include for-tablet-down {
      padding: 24px;
    }

    & .title {
      margin-top: 36px;
      margin-bottom: 0;
      font-size: 30px !important;

      @include for-desktop-down {
        font-size: 24px !important;
        margin-top: 24px;
      }
    }
  }

  & .logo {
    max-width: 240px;
    height: 49px;
    object-fit: cover;

    @include for-desktop-down {
      max-width: 152px;
      height: 32px;
    }

    @include for-phone-only {
      max-width: 142px;
      height: 32px;
    }
  }

  & .icon {
    margin-right: 8px;
  }

  & .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 14px;
  }

  & .tooltip {
    display: flex;
    align-items: center;
    gap: 7px;
  }

  & .main {
    margin-bottom: auto;
    flex: 1 1 auto;
    padding: 190px 36px 216px 40px;

    @include for-desktop-down {
      padding: 154px 20px 190px 24px;
    }

    @include for-phone-only {
      padding: 140px 20px 186px 24px;
    }

    & .text {
      margin-bottom: 32px;
      display: block;
    }

    & .formItem {
      min-height: 72px;
      width: calc(100%);
    }

    & .formItemWithScroll {
      width: calc(100% - 6px);
    }

    & .inputWrapper {
      position: relative;
      padding-top: 26px;

      & .tooltip {
        position: absolute;
        left: 0;
        top: 2px
      }
    }

    & .sendButton {
      margin-bottom: 16px;
      width: 100%;
    }

    & .resetPasswordButton {
      margin-top: 16px;
      padding: 0;
    }

    & .bottomButtons {
      margin-top: 56px;
      display: flex;
      flex-direction: column;
      row-gap: 16px;
    }
  }
}

.checkbox {
  margin-bottom: 14px;
  min-height: auto;
  width: 100%;

  &:global(.ant-form-item-has-error) {
    & span,
    a {
      color: #e64242 !important;
      border-color: #e64242 !important;
    }
  }
}

.checkboxText {
  display: block;
  line-height: 18px;
  margin-bottom: 24px;
}

.checkboxTextBottom {
  display: block;
  line-height: 18px;
  margin-top: 20px;
  margin-bottom: 24px;
}

.link {
  text-decoration: underline;
  color: #f18700;
  position: relative;
  z-index: 100;
}

.checkboxContent {
  align-items: start;

  & > div > span {
    vertical-align: top;
  }
}

.buttonWrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  border-radius: 0 0 8px 8px;
  background: #fff;
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 23%);
  padding: 24px 40px 54px;
  z-index: 100;

  @include for-desktop-down {
    padding: 24px;
  }

  & .sendButton {
    margin-bottom: 16px;
    width: 100%;

    &:last-child {
      margin: 0;
    }
  }
}

.loginRedirectButton {
  align-self: flex-start;
  padding: 0;
  height: auto;
  margin: 0;

  & > div {
    margin: 0;
  }
}

.successWrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  margin-bottom: auto;

  & .title {
    text-align: center;
    margin-bottom: 32px;
    font-size: 30px !important;

    @include for-desktop-down {
      margin-bottom: 24px;
      font-size: 24px !important;
    }
  }

  & .text {
    margin-bottom: auto;
    margin-top: 24px;
    display: block;
    text-align: center;

    .link {
      text-decoration: underline;
      color: #f18700;
      margin-right: 4px;
      margin-left: 4px;
    }
  }
}

.emailButton {
  min-width: 100%;
  margin-top: 32px;
}

.trackY {
  transform: translateX(22px);
}

.scroller {
  margin-right: -17px;
}