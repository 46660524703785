.wrapper {
  overflow: hidden;

  & > * {
    display: flex;
    height: 1px;
    width: 100%;
    margin: 0;
    border: none;
    background: #b3b3bb;
  }
}

.vertical {
  height: 100%;
  width: 1px;
}
