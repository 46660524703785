@import '../../../../app/styles/mixins';

.wrapper {
  padding: 18px 8px;
  display: flex;
  flex-grow: 1;

  @include for-phone-only {
    padding: 0;
  }
}

.container {
  padding: 16px 16px 24px;
  box-shadow: 0 4px 20px 0 rgb(0 0 0 / 10%);
  border-color: #fff;
  transition: 0.2s;

  @include for-phone-only {
    width: 100% !important;
  }

  &:hover {
    box-shadow: 0 2px 18px 0 rgb(0 0 0 / 25%);
  }

  & .header {
    & .imageContainer {
      position: relative;

      & .headerButtons {
        top: 10px;
        right: 10px;
        position: absolute;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }

      & .image {
        width: 100%;
        height: 153px;
        display: block;
        object-fit: cover;
      }

      & .imageBig {
        height: 250px;
      }
    }

    .titleHeader {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      margin-top: 16px;

      & .productName {
        margin: 0;
        hyphens: auto;

        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        max-height: 3em;

        @include for-phone-only {
          font-size: 16px !important;
        }
      }

      & .pointsContainer {
        display: flex;
        align-items: center;
        column-gap: 4px;

        & .pointsIcon {
          width: 16px;
          height: 16px;
        }

        & .productPoints {
          margin: 0;
          width: max-content;
          display: flex;
          align-items: center;
          gap: 4px;

          @include for-phone-only {
            font-size: 14px !important;
          }
        }
      }
    }

    & .recommendation {
      display: block;
      margin-top: 4px;
      height: 16px;
    }
  }

  .productSubLine {
    margin-top: 8px;
    opacity: 0.9;
    height: 44px;
    margin-bottom: 0;

    @include for-phone-only {
      font-size: 12px !important;
    }
  }
}
