.form {
  display: flex;
  width: 100%;
  column-gap: 16px;
  margin-top: 12px;
}

.icon {
  width: 30px;
  height: 30px;
}

.iconWrapper {
  width: 150px;
}