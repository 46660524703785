.emptyRules {
  height: 400px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: 24px;

  & .addNewRuleTitle {
    font-weight: 700;
  }
}