.modal {
  :global(.ant-modal-body) {
    display: flex;
    flex-direction: column;
    align-items: center;

    position: relative;
  }
}

.title {
  margin-bottom: 4px !important;
}

.text {
  margin-bottom: 32px;
  text-align: center;
}

.iconCup {
  position: absolute;
  top: 20px;
}

.confettiImg {
  margin-bottom: 8px;
}

.bonusesList {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 40px;
  padding: 0;
}

.bonusListItem {
  display: flex;
  align-items: center;
  gap: 12px;

  img {
    width: 32px;
    height: 32px;
    border-radius: 3px;
  }
}
